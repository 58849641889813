import React,{useEffect,useState} from 'react'
import Template from '../../components/template/template'



const Home: React.FC = () => {


  return (
    <div>
      <Template></Template>
    </div>
  )
}

export default Home