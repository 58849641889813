import Check from '../check'
import styles from './addCharsOptions.module.scss'
import CONFIG from '../../../config.json'
import useDetectClickedInside from '../../customHooks/useDetectClickedInside'
import { useRef, useEffect } from 'react'
import {CharacterT} from '../../../utils/models'

const {optionItem,optionsPanel} = styles

type AddCharsOptionsPropsT = {
    setShowOptions: (a: boolean) => void
    confirmedValues: CharacterT[]
    isMultiValue: boolean
    setValues: (a) => any
    options: CharacterT[]
}


const AddCharsOptions: React.FC<AddCharsOptionsPropsT> = (props) => {
    const panelEl = useRef<HTMLDivElement | null>(null)

    let isInside = useDetectClickedInside(panelEl);
    useEffect(() => {
        if(!isInside){
            props.setShowOptions(false)
        }
    })

    //it detects whether the character is already exists in the confirmedArr, if it does we have to remove it from confirmedArr
    //if it doesnt exist we have to add it
    const add_or_remove_char_from_confirmed_arr = (id:number) => {
        props.setValues((prev: CharacterT[]) => {
            let returned_characters = []
            const char_exists_in_confirmed_arr = props.confirmedValues.find(char => char.value === id)
            if(char_exists_in_confirmed_arr){
                return [...prev.filter(char => char.value !== id)]
            }
            if(props.isMultiValue){
                return [...prev,props.options.find(char => char.value === id)]
            }
            return [props.options.find(char => char.value === id)]
        })
    }

    const items = props.options.map((op,index) => {
        const isChecked = props.confirmedValues.find(c => c.value === op.value) ? true : false
        let imageElement = null
        //if the option is not a default option(ex: all characters), we have to get the image from server
        if(op.value !== 0){
            imageElement = <img src={`${CONFIG.API_URL_2}/static/characters/${op.value}/avatar.png`} style={{maxWidth: '1.5em'}}/>
        }
        return (
        <div className={optionItem} key={`${index}-dsf`}>
            <Check id={op.value.toString()} value={isChecked} onChangeHandler={() => {add_or_remove_char_from_confirmed_arr(op.value)}} children={imageElement}/>
        </div>
        )
    })
    return(
        <div className={optionsPanel} ref={panelEl}>
            {
            items
            }
        </div>
    )
}


export default AddCharsOptions