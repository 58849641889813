import styles from './userEdit.module.scss'
import React, {useState, useEffect} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector, ConfigsReduxT} from '../../redux/index'
import {RoleT} from '../../utils/models'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import {PATHES} from '../../utils/contants'
import UserListTable from '../../components/tables/userListTable/userListTable'
import {historyPropertiesExistanceCheck,RouterLocationT} from '../../utils/utility'
import Tab from '../../components/tab/tabs'
import UserEditRole from '../../components/panels/userEditRole/userEditRole'
import UserEditStatus from '../../components/panels/userEditStatus/userEditStatus'
import UserEditPolicies from '../../components/panels/userEditPolicies/userEditPolicies'
import UserEditDetails from '../../components/panels/userEditDetails/userEditDetails'
import {InputTypes, SelectContent} from '../../components/formControl/formControl'
import {BackendCompleteUserDocT, ResourcePolicy, ResourceWithActions} from '../../utils/models'
import SpinnerB from '../../components/spinner/spinnerB'
import {Role_WithoutDefaultOption_FC_creator, UserStatus_WithoutDefaultOption_FC_creator, RoleFC_creator,UserStatusFC_creator} from '../../utils/formControl'

import { toast } from 'react-toastify'
import { bindActionCreators } from 'redux'


const {userEdit, container,  } = styles


export type RoleUpdaterArgT = {
  type: 'role'
  payload: number
}

export type StatusUpdatorArgT = {
  type: 'status'
  payload: number
}
export type PoliciesUpdatorArgT = {
  type: 'policies',
  payload: ResourcePolicy[]
}

export type DetailUpdatorArgT = {
  type: 'Detail',
  payload: {
    first_name: string
    last_name: string
    username: string
    phone_number: string
  }
}
export type UpdatorArgT = RoleUpdaterArgT | StatusUpdatorArgT | DetailUpdatorArgT | PoliciesUpdatorArgT


const UserEdit:React.FC = props => {

  const location = useLocation()
  const userData: BackendCompleteUserDocT = historyPropertiesExistanceCheck(location, 'userData', null)
  const from = historyPropertiesExistanceCheck(location,'from',null)
  const {user: userRedux, configs: configsRedux} = useTypedSelector(state => ({user: state.user,configs: state.configs}))
  const navigate = useNavigate()
  const [selectedTabId, setSelectedTabId] = useState(1)
  //to put all dropdown options in role select and status select
  const [roleFC, setRoleFC] = useState<SelectContent | null>(null)
  const [statusFC, setStatusFC] = useState<SelectContent | null>(null)
  const {first_name, last_name, username, phone_number, email, created_at, updated_at, id: userId, role: userRole, status: userStatus} = userData.user


  // console.log('userData ====>', userData)
  // console.log('from ====>', from)
  //if user doesnt come from '/user' page, is not allowed to see this page, so we have to redirect hime to the home
  let illegalAccess = false
  if(from !== PATHES.user){
    illegalAccess = true
  }





  //we have to put all roles as statuses options in dropdowns after getting them from redux
  useEffect(() => {
    if(configsRedux.allRoles.length > 0){
      setRoleFC(Role_WithoutDefaultOption_FC_creator(configsRedux.allRoles))
    }
    if(configsRedux.allStatuses.length > 0){
      setStatusFC(UserStatus_WithoutDefaultOption_FC_creator(configsRedux.allStatuses))
    }
  },[configsRedux])


 


  const previousPageBtnProps: ButtonAProps = {
    type: 'secondary',
    children: 'Back to previous page',
    onClick: () => {navigate({pathname: PATHES.user})}
  }


  //its responsible to update the router-state and make it persistant against browser refresh
  const userDataUpdater = (arg: UpdatorArgT) => {
    let updatedState = userData
    if(arg.type === 'role'){
      updatedState.user.role = arg.payload
    }
    else if(arg.type === 'status'){
      updatedState.user.status = arg.payload
    }
    else if(arg.type === 'Detail'){
      updatedState.user = {
        ...updatedState.user,
        first_name: arg.payload.first_name,
        last_name: arg.payload.last_name,
        username: arg.payload.username,
        phone_number: arg.payload.phone_number
      }
    }
    else if(arg.type === 'policies'){
      let updatedPermissions: ResourceWithActions[] = []

      for(const resource of configsRedux.allResources){
        //then we have to extract resources
        const related_resource_policies = arg.payload.filter(policy => policy.resource === resource.value && !policy.is_denied)
        updatedPermissions.push({
          name: resource.name,
          value: resource.value,
          actions: related_resource_policies.map(policy => {
            return {
              value: policy.action,
              name: configsRedux.allActions.find(ac => ac.value === policy.action).name
            }
          })
        })
      }
      updatedState.permissions = updatedPermissions
    }
    navigate({pathname: PATHES.userEdit},{state: {from: PATHES.user, userData: updatedState}, replace: true}) 
  }
  




  return (
    <>
    {
    illegalAccess ?
    <Navigate to={{pathname: PATHES.home}} replace={true}/>
    :
    roleFC && statusFC && userRedux && userRedux.access_token  ?
    <Template>
      <div className={userEdit}>
        <MainPanel title='User edit' headButtons={[previousPageBtnProps]}>
          <div className={container}>
            <Tab data={[{id: 1,title: 'Details'},{id: 2, title: 'Role'},{id: 3, title: 'Policies'},{id: 4, title: 'Status'}]} 
                components={[
                  <UserEditDetails data={{id: userId, first_name,last_name,phone_number,created_at,updated_at,email,username}} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} userDataUpdater={(obj) => userDataUpdater(obj)}/>,
                  <UserEditRole role={userRole} roleFC={roleFC} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} userId={userData.user.id} userDataUpdater={(obj) => userDataUpdater(obj)}/>,
                  <UserEditPolicies configs={configsRedux} userData={userData} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} userDataUpdater={(obj) => userDataUpdater(obj)}/>,
                  <UserEditStatus status={userStatus} statusFC={statusFC} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} userId={userData.user.id} userDataUpdater={(obj) => userDataUpdater(obj)}/>,
                ]}
                selectedTabId={selectedTabId}
                tabChanger={(tabId) => setSelectedTabId(tabId)}
            />
          </div>
        </MainPanel>
      </div>
    </Template>
    :
    <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
      <SpinnerB/>
    </div>
    }
    </>
  )
}

export default UserEdit

