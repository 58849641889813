import IMGS_URL from '../config.json'
import EmailImg from '../assets/images/email.svg';
import PasswordImg from '../assets/images/password.svg';
import { RoleT, StatusT, LanguageT, CharT, CharacterT } from './models'
import { OtherInputsContent, SelectContent, TextAreaContent, InputTypes } from '../components/formControl/formControl'

//FC ===> formcontrol config
//stateV ===> state value


export const firstNameFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'firstName',
}


export const lastNameFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'lastName'
}

export const emailFC: OtherInputsContent = {
  type: InputTypes.email,
  name: 'email'
}

export const emailX2FC: OtherInputsContent = {
  type: InputTypes.email,
  name: 'email',
  label: 'Email',
}

export const userNameFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'userName'
}

export const phoneNumberFC: OtherInputsContent = {
  type: InputTypes.tel,
  name: 'phoneNumber',
  placeHolder: 'ex: 09121234567'
}

//------------------------------------------------
export const passwordX2FC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'Password',
  label: 'Password',
  icon: PasswordImg,
  iconHover: PasswordImg
}

export const confirmPasswordX2FC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'confirmPassword',
  label: 'confirm password',
  icon: PasswordImg,
  iconHover: PasswordImg
}

export const oldPasswordFC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'oldPassword',
}

export const newPasswordFC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'newPassword'
}

export const confirmNewPasswordFC: OtherInputsContent = {
  type: InputTypes.password,
  name: 'confirmNewPassword'
}

export const VerificationCodeFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'verificationCode',
  label: 'verification code'
}

export const FirstAccountIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'firstAccountId',
}
export const SecondAccountIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'secondAccountId',
}
export const FirstDeviceIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'firstDeviceId',
}
export const SecondDeviceIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'secondDeviceId',
}
export const TalkIdFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'talkId',
}
export const RefreshTimeFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'refreshTime',
}


//-----------------------------------------------

export const searchFC: OtherInputsContent = {
  type: InputTypes.text,
  name: 'search'
}

export const RoleFC_creator = (roles: RoleT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'role',
    options: [{ label: 'choose one role', value: 0 }, ...roles.map(role => ({ label: role.name, value: role.value }))],
  }
}

export const Role_WithoutDefaultOption_FC_creator = (roles: RoleT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'role',
    options: roles.map(role => ({ label: role.name, value: role.value })),
  }
}

export const UserStatusFC_creator = (statuses: StatusT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'userStatus',
    options: [{ label: 'choose one status', value: 0 }, ...statuses.map(status => ({ label: status.name, value: status.value }))],
  }
}

export const UserStatus_WithoutDefaultOption_FC_creator = (statuses: StatusT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'userStatus',
    options: statuses.map(status => ({ label: status.name, value: status.value }))
  }
}


export const langFC_creator = (langs: LanguageT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'languages',
    options: [{label: 'filter by language', value: 0},...langs.map(lang => ({ label: lang.name, value: lang.value }))]
  }
}

export const characterFC_creator = (chars: CharacterT[]): SelectContent => {
  return {
    type: InputTypes.select,
    name: 'characters',
    options: [{label: 'filter by charcters', value: 0},...chars.map(char => ({label: char.name, value: char.value}))]
  }
}


