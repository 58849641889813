import styles from './profile.module.scss'
import React from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector} from '../../redux/index'
import { useNavigate } from 'react-router-dom'
import {PATHES} from '../../utils/contants'

const {profile, container, item, itemLabel, itemValue, firstName, lastName, email, phoneNumber, userName, role } = styles

const Profile:React.FC = props => {
    const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
    const navigate = useNavigate()

    //to find out whether has permission to change his password and his information
    const user_is_permitted_to_update_profile = userRedux.resources.find(reso => reso.value === 5)?.actions.find(act => act.value === 4) ? true : false

    let btnPropsArr: ButtonAProps[] = []
    if(user_is_permitted_to_update_profile){
        const editBtnProps: ButtonAProps = {
            type: 'primary',
            children: 'Edit your profile',
            onClick: () => {navigate({pathname: PATHES.profileEdit},{state: {from: PATHES.profile}})}
        }
        const changePassBtnProps: ButtonAProps = {
            type: 'secondary',
            children: 'Change password',
            onClick: () => {navigate({pathname: PATHES.profileChangePassword},{state: {from: PATHES.profile}})}
        }
        btnPropsArr = [editBtnProps,changePassBtnProps]
    }



    return (
      <Template>
        <div className={profile}>
           <MainPanel title='Your profile' headButtons={[...btnPropsArr]}>
                <div className={container}>
                    <div className={[item,firstName].join(' ')}>
                        <div className={itemLabel}>first name</div>
                        <div className={itemValue}>{userRedux.firstName ? userRedux.firstName : ''}</div>
                    </div>
                    <div className={[item,lastName].join(' ')}>
                        <div className={itemLabel}>last name</div>
                        <div className={itemValue}>{userRedux.lastName ? userRedux.lastName : ''}</div>
                    </div>
                    <div className={[item,role].join(' ')}>
                        <div className={itemLabel}>role</div>
                        <div className={itemValue}>{configRedux.allRoles.find((role) => role.value === userRedux.role)?.name}</div>
                    </div>
                    <div className={[item,email].join(' ')}>
                        <div className={itemLabel}>email</div>
                        <div className={itemValue}>{userRedux.email ? userRedux.email : ''}</div>
                    </div>
                    <div className={[item,userName].join(' ')}>
                        <div className={itemLabel}>user name</div>
                        <div className={itemValue}>{userRedux.userName ? userRedux.userName : ''}</div>
                    </div>
                    <div className={[item,phoneNumber].join(' ')}>
                        <div className={itemLabel}>phone number</div>
                        <div className={itemValue}>{userRedux.phoneNumber ? userRedux.phoneNumber : ''}</div>
                    </div>
                </div>
           </MainPanel>
        </div>
      </Template>
    )
}

export default Profile

