import Template from '../../../components/template/template'
import MainPanel from '../../../components/panels/mainPanel/mainPanel'
import { useState, useEffect } from 'react'
import SpinnerB from '../../../components/spinner/spinnerB'
import styles from './languageCount.module.scss'
import { useTypedSelector, useDispatch } from '../../../redux'
import {SelectContent} from '../../../components/formControl/formControl'
import {CharacterT, TalkReportsDataT} from '../../../utils/models'
import {langFC_creator} from '../../../utils/formControl'
import { useNavigate } from 'react-router-dom'
import LanguageCount from '../../../components/panels/languageCount/languageCount'

import FilterD from '../../../components/filters/filterD'
import {filter_talk_reports_v2} from '../../../utils/others'
import { useMemo } from 'react'
import { changeHourOfISODate} from '../../../utils/utility'
import {toast} from 'react-toastify'
import {GetTalkStats, GetTalkStatsFiltersT} from '../../../utils/requests'
import {characterFC_creator} from '../../../utils/formControl'

const {talkReports, container, body, filterSpot} = styles

const TalkReportsLanguageCount = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [talks, setTalks] = useState<TalkReportsDataT | null>(null)
  const [langFC, setLangFC] = useState<SelectContent | null>(null)
  const [charFC, setCharFC] = useState<SelectContent| null>(null)

  const [firstCharV, setFirstCharV] = useState<number | null>(null)
  const [secondCharV, setSecondCharV] = useState<number | null>(null)
  const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({from: null, to: null})
 

  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async (access_token: string,filterPhrase: GetTalkStatsFiltersT) => {
      try {
        setLoading(true)
        const res = await GetTalkStats({token: access_token,filters: filterPhrase},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
        setLoading(false)
        if(res.status === 200){
            const resData = res.data as TalkReportsDataT
            if(resData){
                setTalks(resData)
            }
            else {
                setTalks(null)
            }
        }
      }
      catch(err){
        setLoading(false)
      }
    }
    if(userRedux.access_token && isFirstRender){
      fetchData(userRedux.access_token, {})
      setIsFirstRender(false)
    }
  }
  ,[userRedux])


  //configs based on roles and status comes from redux for dropdown
  useEffect(() => {
    if(configRedux.allLanguages.length > 0){
      setLangFC(langFC_creator(configRedux.allLanguages))
    }
    if(configRedux.allCharacters.length > 0){
      const charFC = characterFC_creator(configRedux.allCharacters)
      setCharFC(charFC)
      setFirstCharV(+charFC.options.find(char => char.value === 0)!.value)
      setSecondCharV(+charFC.options.find(char => char.value === 0)!.value)
    }
 
  },[configRedux])


  

  const reconstructedData = useMemo(() => {
    if(!talks){
      return
    }
    return filter_talk_reports_v2(talks.languages, 'ALL', firstCharV, secondCharV)
  }, [talks, firstCharV, secondCharV])

  const fetchData = async (access_token: string,filterPhrase: GetTalkStatsFiltersT) => {
    try {
      setLoading(true)
      const res = await GetTalkStats({token: access_token,filters: filterPhrase},{dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
      setLoading(false)
      if(res.status === 200){
        const resData = res.data as any
        if(resData){
          setTalks(resData)
        }
        else {
          setTalks(null)
        }
      }
    }
    catch(err){
      setLoading(false)
    }
  }

  const confirmHandler = async() => {
    if(loading){
        return
    }
    //we want to validate inputs 
   
    //first we have to populate input values if exists any
    const filteredPhrase: GetTalkStatsFiltersT = {}
  

 
    if(createdAt && createdAt.from){
        const from = createdAt.from
        filteredPhrase.from = changeHourOfISODate(from, 0, 0, 0)
    }
    if(createdAt && createdAt.to){
        const to = createdAt.to
        filteredPhrase.to = changeHourOfISODate(to, 23, 59, 59)
    }

    await fetchData(userRedux.access_token, filteredPhrase)
  }

  const firstCharChanger = (event: any, value: any, label: any, object: any) => {
    //we want to refresh the secondCharValue to default whenever firstCharValue is set to default
    if(value === 0){
      setSecondCharV(0)
    }
    setFirstCharV(value)
  }

  const secondCharChanger = (event: any, value: any, label: any, object: any) => {
    setSecondCharV(value)
  }

  return(
    <Template>
      <div className={talkReports}>
        {
        // !loading && langFC && allChars ?
        !loading && langFC && charFC && talks ?
        <MainPanel title="Talks Reports, Language's count" headButtons={[]}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            <div className={filterSpot}>
              <FilterD 
                  charFC={charFC} firstCharValue={firstCharV} setFirstCharValue={firstCharChanger} secondCharValue={secondCharV} setSecondCharValue={secondCharChanger}
                  confirmHandler={confirmHandler} loading={loading}
                  createdAt={createdAt} setCreatedAt={setCreatedAt} 
                  />
            </div>
            <div className={body}>
              <LanguageCount data={reconstructedData}/>
            </div>
          </div>
        </MainPanel>
        :
        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          <SpinnerB/>
        </div>
        }
      </div>
  </Template>  
  )
}




export default TalkReportsLanguageCount