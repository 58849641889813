

export const PATHES = {
  login: '/',
  forgotPassword: '/forgot-password',
  refreshPassword: '/refresh-password',
  home: '/',
  profile: '/profile',
  profileEdit: '/profile-edit',
  profileChangePassword: '/profile-change-password',
  userCreate: '/user-create',
  userEdit: '/user-edit',
  policyList: '/policy-list',
  policyEdit: '/policy-edit',
  talkReportsLanguageCount: '/talk-reports-language-count',
  talkReportsLanguageDuration: '/talk-reports-language-duration',
  talkReportsCharacterCount: '/talk-reports-character-count',
  talkReportsCharacterDuration: '/talk-reports-character-duration',
  talkList: '/talk-list',
  talkAnother: '/talk-another',

  /*dynamic pathes*/
  access_policy: '/policy-list',
  // account: '/account',
  // context: '/context',
  // pal: '/pal',
  talk: '/talk',
  // feedback: '/feedback',
  user: '/user-list',
  // config: '/config',
  // language: '/language',
  // character: '/charachter'

}



export const SUBLINKS = {
  '10': {
    sublinks: [
      {
        name: 'language count',
        value: '/talk-reports-language-count'
      },
      {
        name: 'language duration',
        value: '/talk-reports-language-duration'
      },
      {
        name: 'character count',
        value: '/talk-reports-character-count'
      },
      {
        name: 'character duration',
        value: '/talk-reports-character-duration'
      },
      {
        name: 'talk list',
        value:  '/talk-list',
      },
      {
        name: 'talk another',
        value: '/talk-another'
      }
    ]
  }
}



export const GROUPS =  {
  1: {
    name: 'AAA',
    color: 'cyan'
  },
  2: {
    name: 'BBB',
    color: 'lightblue'
  },
  3: {
    name: 'CCC',
    color: 'red'
  },
  4: {
    name: 'DDD',
    color: 'orange'
  }
}


export const PASSWORD_MIN_LENGTH = 8
export const USERNAME_MIN_LENGTH = 5
export const NAME_MIN_LENGTH = 3
export const USER_PER_PAGE = 10


export const SESSION_STORAGE_KEYS = {
  user: 'user',
  configs: 'configs'
}


export const static_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTI4LCJwbGF0Zm9ybSI6MiwiYXBwX3ZlcnNpb24iOiIxLjEuMSIsImRldmljZV9pZCI6ImRhc2QiLCJleHAiOjE2NjA4MjgxNDN9.BokpQhgGgEyvu9ok2c703AC64INYDEUwchvY6051PA4"