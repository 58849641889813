import { ConfigsReduxT, ConfigsReduxActionTypesT, ConfigsReduxActionsT } from './types'
import { SESSION_STORAGE_KEYS } from '../../utils/contants'


const initialState: ConfigsReduxT = {
    allRoles: [],
    allResources: [],
    allActions: [],
    allStatuses: [],
    allLanguages: [],
    allCharacters: []
}



const configsReducer = (state = initialState, action: ConfigsReduxActionsT): ConfigsReduxT => {
    switch (action.type) {
        case ConfigsReduxActionTypesT.saveConfigs: {
            const newState: ConfigsReduxT = {
                ...state,
                allRoles: action.payload.roles,
                allResources: action.payload.resources,
                allActions: action.payload.actions,
                allStatuses: action.payload.statuses,
                allLanguages: action.payload.languages,
                allCharacters: action.payload.characters
            }
            //save into the session-storage
            sessionStorage.setItem(SESSION_STORAGE_KEYS.configs, JSON.stringify(newState))
            return newState
        }

        case ConfigsReduxActionTypesT.saveWholeStateFromSessionStorage: {
            return action.payload
        }

        case ConfigsReduxActionTypesT.removeConfigs: {
            sessionStorage.removeItem(SESSION_STORAGE_KEYS.configs)
            return initialState
        }

        default:
            return state
    }
}


export default configsReducer