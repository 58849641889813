import React, { useEffect, useState } from 'react'
import styles from './rolePolicyListTable.module.scss'
import {useTypedSelector} from '../../../redux/index'
import { InputTypes,SelectContent } from '../../formControl/formControl'
import PenImg from '../../../assets/images/pen.svg'
import {PATHES} from '../../../utils/contants'
import {BackendUserT,ResourceWithActions,ResourceT,RoleT, StatusT, ActionT, BackendRoleBasedAccessPolicyT} from '../../../utils/models'
import { useNavigate } from 'react-router-dom'
import {RoleFC_creator,UserStatusFC_creator,searchFC} from '../../../utils/formControl'
import Filter from '../../filters/filterA'
import MobilePagination from '../../pagination/mobilePagination'
import {GetRolePoliciesResT, getRolePolcies} from '../../../utils/requests'
import { create } from 'domain'
import { setEnvironmentData } from 'worker_threads'
import {USER_PER_PAGE} from '../../../utils/contants'
import {toast} from 'react-toastify'
import Check from '../../formControl/check'
import {useDispatch} from '../../../redux/index'

const {tableBox, filterBox, policyListTable, headRow, bodyRow, userTable , noRecord} = styles

type PolicyListTableProps = {
    roleFC: SelectContent
    allRoles: RoleT[]
    allActions: ActionT[]
    allResources: ResourceT[]
    access_token: string
    refresh_token: string
    user_is_permitted_to_edit_policies: boolean
}


const PolicyListTable: React.FC<PolicyListTableProps> = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [policies,setPolicies] = useState<ResourceWithActions[]>([])
    const [roleV,setRoleV] = useState(props.allRoles[0].value)   //by default we want to select the first role from the configs
    const [current_policies_related_role, set_current_policies_related_role] = useState<RoleT | null>(props.allRoles[0])
    const [loading, setLoading] = useState(false)
    

    const editHandler = () => {
        navigate({pathname: PATHES.policyEdit},{state: {from: PATHES.policyList, policyData: {policies, role: current_policies_related_role}}})
    }

    const roleChanger = (e: any, value: number, label: string, object: object) => {
        const selectedRole = props.roleFC.options.find((role) => role.value === value)
        setRoleV(+selectedRole.value)
    }


    //for the first fetch 
    useEffect(() => {
        const fetchData = async (token,role) => {
            try {
                const res = await getRolePolcies(props.access_token,role,{dispatch,navigate,refresh_token: props.refresh_token,toast})
                if(res.status === 200){
                    const data = res.data as GetRolePoliciesResT
                    let newPolicies = []
                    if(Array.isArray(data.permissions)){
                       newPolicies = data.permissions
                    }
                    return setPolicies(newPolicies)
                }
                //if any error occures
                setPolicies([])
                set_current_policies_related_role(null)
            }
            catch(err){
                setPolicies([])
                set_current_policies_related_role(null)
            }
        }
        if(props.access_token){
            fetchData(props.access_token,roleV)
        }
    },[props.access_token])

    const fetchData = async (token,role) => {
        try {
            setLoading(true)
            const res = await getRolePolcies(token,role,{dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
                const data = res.data as GetRolePoliciesResT
                let newPolicies = []
                if(Array.isArray(data.permissions)){
                    newPolicies = data.permissions
                }
                setPolicies(newPolicies)
                set_current_policies_related_role(props.allRoles.find(roleee => roleee.value === role))
                return toast.success('selected role policies was recieved successfully')
            }
            //if any error occures
            setPolicies([])
            set_current_policies_related_role(null)
            setLoading(false)
        }
        catch(err){
            setPolicies([])
            set_current_policies_related_role(null)
            setLoading(false)
        }
    }

    const confirmHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        await fetchData(props.access_token, roleV)
    }


    //1- first we have to build our table header
    //first we sort all actions in our desired manner then we create their corresponding tableCell for showing as head of columns
    const sortedActions = props.allActions.sort((firstEl,secondEL) => {
        if(firstEl.value < secondEL.value) { return 1; }
    	if(firstEl.value > secondEL.value) { return -1; }
    	return 0;
    })
    const allActionNameCellsInHeadOfTable = sortedActions.map((action,index) => {
        const desc = action.value !== 1 ? action.name : 'All'
        //we dont want to show name of '*' for action => {name: '*', value: 1}
        return (<td key={`${index}-sajg`}>{desc}</td>)
    })

    const get_related_action_cells_for_a_resource = (resource_value: number) => {
        const related_resource_actions_in_policies = policies.find(policy => policy.value === resource_value)
        return sortedActions.map((action,index) => {
            const resource_action_id = `${resource_value}__${action.value}`
            //we have to find out whether this resource_action policy exists in the actions arr, to check the checkbox or not
            const role_has_policy_for_current_resource_and_action = related_resource_actions_in_policies?.actions?.find(act => act.value === action.value) 
            return (
                <td key={resource_action_id}>
                     <Check id={resource_action_id} value={role_has_policy_for_current_resource_and_action ? true : false} 
                            onChangeHandler={(e) => {}}/>
                </td>
            )
        })
    }

    const rows = props.allResources.map((resource,index) => {
        return (
            <tr className={bodyRow} key={`${index}-sdas`}>
                <td>{index+1}</td>
                <td>{current_policies_related_role.name}</td>
                <td>{resource.value !== 1 ? resource.name : 'all'}</td>
                {get_related_action_cells_for_a_resource(resource.value)}
                <td>
                    {
                    props.user_is_permitted_to_edit_policies ? <img src={PenImg} onClick={() => editHandler()}/> : false
                    }
                </td>
            </tr>
        )
    })


  


    return(
        <div className={policyListTable}>
            <div className={filterBox}>
                <Filter confirmHandler={confirmHandler} roleContent={props.roleFC} roleValue={roleV} setRoleValue={roleChanger} loading={loading}/>
            </div>
            <div className={tableBox}>
                <table className={userTable}>
                    <thead>
                        <tr className={headRow}>
                            <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                            <td>Role</td>
                            <td>Resource</td>
                            {allActionNameCellsInHeadOfTable}
                            <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        rows.length > 0 ?
                        rows : 
                        <tr className={noRecord}><td colSpan={10}>there is no record</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>

    )
}

export default PolicyListTable







