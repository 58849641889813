/*
CanvasJS React Charts - https://canvasjs.com/
Copyright 2021 fenopix

--------------------- License Information --------------------
CanvasJS is a commercial product which requires purchase of license. Without a commercial license you can use it for evaluation purposes for upto 30 days. Please refer to the following link for further details.
https://canvasjs.com/license/

*/
import React from 'react'
import CanvasJs from './lib/canvas.min'
const CanvasJS = CanvasJs.Chart ? CanvasJs : window.CanvasJS;




// interface ChartOptions {
// 	interactivityEnabled?: boolean | undefined;
// 	animationDuration?: number | undefined;
// 	animationEnabled?: boolean | undefined;
// 	exportFileName?: string | undefined;
// 	exportEnabled?: boolean | undefined;
// 	zoomEnabled?: boolean | undefined;
// 	theme?: string | undefined;
// 	backgroundColor?: string | undefined;
// 	colorSet?: string | undefined;
// 	culture?: string | undefined;
// 	width?: number | undefined;
// 	height?: number | undefined;
// 	dataPointMaxWidth?: number | undefined;
// 	dataPointMinWidth?: number | undefined;
// 	dataPointWidth?: number | undefined;
// 	title: ChartTitleOptions;
// 	legend?: ChartLegendOptions | undefined;
// 	axisX?: ChartAxisXOptions | ChartAxisXOptions[] | undefined;
// 	axisX2?: ChartAxisXOptions | ChartAxisXOptions[] | undefined;
// 	axisY?: ChartAxisYOptions | ChartAxisYOptions[] | undefined;
// 	axisY2?: ChartAxisYOptions | ChartAxisYOptions[] | undefined;
// 	toolTip?: ChartToolTipOptions | undefined;
// 	data: ChartDataSeriesOptions[];
// 	subtitles?: ChartTitleOptions[] | undefined;
// }



export type CanvasJSOptionsT = CanvasJs.ChartOptions

type CanvasJSReactPropsT = {
	containerProps: any,
	options: CanvasJSOptionsT,
	onRef: any
}





class CanvasJSChart extends React.Component<CanvasJSReactPropsT> {
	static _cjsContainerId = 0
	options: any
	containerProps: any
	chartContainerId: any
	chart: any
	constructor(props: CanvasJSReactPropsT) {
		super(props)
		this.options = props.options ? props.options : {};
		this.containerProps = props.containerProps ? props.containerProps : { width: "100%", position: "relative" };
		this.containerProps.height = props.containerProps && props.containerProps.height ? props.containerProps.height : this.options.height ? this.options.height + "px" : "400px";
		this.chartContainerId = "canvasjs-react-chart-container-" + CanvasJSChart._cjsContainerId++;
	}
	
	componentDidMount() {
		//Create Chart and Render		
		this.chart = new CanvasJS.Chart(this.chartContainerId, this.options);
		this.chart.render();

		if (this.props.onRef)
			this.props.onRef(this.chart);
	}
	shouldComponentUpdate(nextProps, nextState) {
		//Check if Chart-options has changed and determine if component has to be updated
		return !(nextProps.options === this.options);
	}
	componentDidUpdate() {
		//Update Chart Options & Render
		this.chart.options = this.props.options;
		this.chart.render();
	}
	componentWillUnmount() {
		//Destroy chart and remove reference
		this.chart.destroy();
		if (this.props.onRef)
			this.props.onRef(undefined);
		}
	render() {
		//return React.createElement('div', { id: this.chartContainerId, style: this.containerProps });		
		return <div id={this.chartContainerId} style={this.containerProps} />
	}
}

var CanvasJSReact = {
	CanvasJSChart: CanvasJSChart,
	CanvasJS: CanvasJS
};

export default CanvasJSReact;

