import styles from './filterB.module.scss'

import DatePicker from '../datePicker/reactDatePicker'
import ButtonA from '../buttons/buttonA'
import RightImg from '../../assets/images/right.png'
import CalenderImg from '../../assets/images/calender.png'
import AddCharsFormControl from '../formControl/addCharsFormControl/addCharsFormControl'

import FormControl,{SelectContent, OtherInputsContent} from '../formControl/formControl'
import { CharacterT } from '../../utils/models'
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory'

const {filter, filterItem, filterLabel, filterValue, arrIcon, calIcon, talkId,firstChars,secondChars, lang, firstAccount, secondAccount, firstDevice, secondDevice, createdRange, confirmBtn, dateBox} = styles


type FilterProps = {
    createdAt?: {from: Date, to: Date}
    setCreatedAt?: (a: any) => void

    talkIDContent: OtherInputsContent
    talkIDValue: string
    setTalkIDValue: (a: string) => void

    langsFC: SelectContent
    langValue: number
    setLangValue: (e: any, value: number, label: string, object: object) => void

    allChars: CharacterT[]
    firstCharValues: CharacterT[]
    setFirstCharValues: (a) => void

    secondCharValues: CharacterT[]
    setSecondCharValues: (a) => void

    firstAccountIDContent?: OtherInputsContent
    firstAccountIDValue?: string
    setFirstAccountIDValue?: (a: string) => void

    secondAccountIDContent?: OtherInputsContent
    secondAccountIDValue?: string
    setSecondAccountIDValue?: (a: string) => void

    firstDeviceIDContent?: OtherInputsContent
    firstDeviceIDValue?: string
    setFirstDeviceIDValue?: (a: string) => void

    secondDeviceIDContent?: OtherInputsContent
    secondDeviceIDValue?: string
    setSecondDeviceIDValue?: (a: string) => void
    confirmHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
    loading: boolean
}

const Filter:React.FC<FilterProps> = ({
    talkIDContent, talkIDValue, setTalkIDValue,
    langsFC, langValue, setLangValue,
    allChars, firstCharValues, setFirstCharValues, secondCharValues, setSecondCharValues,
    firstAccountIDContent, firstAccountIDValue, setFirstAccountIDValue,
    secondAccountIDContent, secondAccountIDValue, setSecondAccountIDValue,
    firstDeviceIDContent, firstDeviceIDValue, setFirstDeviceIDValue,
    secondDeviceIDContent, secondDeviceIDValue, setSecondDeviceIDValue,
    createdAt, setCreatedAt,
    confirmHandler, loading
}) => {
    
    return (
    <div className={filter}>
   
        {
        createdAt && setCreatedAt ?
        <div className={[filterItem, createdRange].join(' ')}>
            <div className={filterLabel}>created at:</div>
            <div className={filterValue}>
                <div className={dateBox}><DatePicker date={createdAt.from} setDate={(date) => {setCreatedAt(prev => ({...prev,from: date}))}} placeholder="Start date"/></div>
                <img src={RightImg} className={arrIcon}/>
                <div className={dateBox}><DatePicker date={createdAt.to} setDate={(date) => {setCreatedAt(prev => ({...prev, to: date}))}} placeholder="End date"/></div>
                <img src={CalenderImg} className={calIcon}/>
            </div>
        </div>
        :""
        }
        
        {
        talkIDContent ?
        <div className={[filterItem, talkId].join(' ')}>
            <div className={filterLabel}>talk ID:</div>
            <div className={filterValue}>
                <FormControl content={talkIDContent} value={talkIDValue} setParentValue={setTalkIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }

        {
        langsFC ?
        <div className={[filterItem, lang].join(' ')}>
            <div className={filterLabel}>language:</div>
            <div className={filterValue}>
                <FormControl content={langsFC} value={langValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setLangValue}/>
            </div>
        </div>
        :""
        }
        {
        firstAccountIDContent ?
        <div className={[filterItem, firstAccount].join(' ')}>
            <div className={filterLabel}>first account ID:</div>
            <div className={filterValue}>
                <FormControl content={firstAccountIDContent} value={firstAccountIDValue} setParentValue={setFirstAccountIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        secondAccountIDContent ?
        <div className={[filterItem, secondAccount].join(' ')}>
            <div className={filterLabel}>second account ID:</div>
            <div className={filterValue}>
                <FormControl content={secondAccountIDContent} value={secondAccountIDValue} setParentValue={setSecondAccountIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        firstDeviceIDContent ?
        <div className={[filterItem, firstDevice].join(' ')}>
            <div className={filterLabel}>first device ID:</div>
            <div className={filterValue}>
                <FormControl content={firstDeviceIDContent} value={firstDeviceIDValue} setParentValue={setFirstDeviceIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        secondDeviceIDContent ?
        <div className={[filterItem, secondDevice].join(' ')}>
            <div className={filterLabel}>second device ID:</div>
            <div className={filterValue}>
                <FormControl content={secondDeviceIDContent} value={secondDeviceIDValue} setParentValue={setSecondDeviceIDValue} isValid={true} setIsValid={() => {}} errorMsg={null}/>
            </div>
        </div>
        :""
        }
        {
        <div className={[filterItem, firstChars].join(' ')}>
            <div className={filterLabel}>character:</div>
            <div className={filterValue}>
                <AddCharsFormControl values={firstCharValues} setValues={setFirstCharValues} name="character" options={allChars} isMultiValue={false}/>
            </div>
        </div>
        }
        {/* {
        <div className={[filterItem, secondChars].join(' ')}>
            <div className={filterLabel}>second chracters:</div>
            <div className={filterValue}>
                <AddCharsFormControl values={secondCharValues} setValues={setSecondCharValues} name="character" options={allChars}/>
            </div>
        </div>
        } */}
        
        <div className={confirmBtn}>
            <ButtonA children={'filter result'} type='primary' loading={loading} onClick={confirmHandler}/>
        </div>
    </div>
    )
}


export default Filter