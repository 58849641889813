import React,{useState} from 'react'
import styles from './rolePolicyEditTable.module.scss'
import {ActionT, ResourceT, RoleT, ResourcePolicy, BackendRoleBasedAccessPolicyT, ResourceWithActions} from '../../../utils/models'
import Check from '../../formControl/check'
import {PolicyDataT} from '../../../pages/policyEdit/policyEdit'
import {
    update_wildCard_wildCart_action_permissions_in_resource_policies,
    update_wildCard_action_permissions_in_resource_policies,
    extact_action_permission_from_recourcePolicies,
    extract_changed_access_policies,
    resourcePolicies_creator_from_role_policies,
    update_action_permission_in_resource_policies_arr_v2,
    modify_req_data_for_update_policies_based_on_backend_instruction_v2,
    update_local_access_policies_based_on_confirmed_changed_policies_from_the_server_response_v3,
    DirectionT
} from '../../../utils/others'
import ButtonA from '../../buttons/buttonA'
import {toast} from 'react-toastify'
import {useDispatch} from '../../../redux/index'
import {useNavigate} from 'react-router-dom'

import {adminEditRoleAccessPolicies, AdminEditRoleAccessPolicyReqT, AdminEditRoleAccessPolicyResT} from '../../../utils/requests'
const {rolePolicyEditTable, table, bodyRow, changed, confirmed, confirmBtn, tableConatiner} = styles

type RolePolicyEditTablePropsT = {
    allActions: ActionT[]
    allResources: ResourceT[]
    policyData: PolicyDataT
    access_token: string
    refresh_token: string
    policyDataUpdater: (arg: ResourcePolicy[]) => void
}




type ReturnTypeX12 = {
    final_access_policies: ResourcePolicy[]
    confirmed_policies: ResourcePolicy[]
}
// const update_local_access_policies_based_on_confirmed_changed_policies_from_the_server_response = (locally_changed_policies: ResourcePolicy[], serverResponse : {data: AdminEditRoleAccessPolicyResT}, initial_resource_policies: ResourcePolicy[]): ReturnTypeX12 => {
//     //to find the confirmed policies from the backend res
//     // console.log('locally_changed_policies ===>',locally_changed_policies)
//     const confirmed_policies = locally_changed_policies.filter(changedPolicy => {
//         return serverResponse.data.access_policies.find(confirmedPolicy => {
//             return confirmedPolicy.action === changedPolicy.action && confirmedPolicy.resource === changedPolicy.resource && confirmedPolicy.is_applied
//         })
//     })
//     // console.log('confirmed_policies ===>',confirmed_policies)
//     //then we have update these confirmed policies in the initial_access_policies
//     const final_access_policies = initial_resource_policies.map(initialPolicy => {
//         const related_confirmed_policy = confirmed_policies.find(confirm_policy => confirm_policy.resource === initialPolicy.resource && confirm_policy.action === initialPolicy.action)
//         //if this policy is not found, it means it is not changed
//         // console.log('related_confirmed_policy ====>',related_confirmed_policy)
//         if(!related_confirmed_policy){
//             return initialPolicy
//         }
//         //if this policy is changed, we have to update its corresponding is_denied value
//         else {
//             return {
//                 ...initialPolicy,
//                 is_denied: related_confirmed_policy.is_denied
//             }
//         }
//     })
//     return {final_access_policies,confirmed_policies}
// }

//it creates a resourcePolicies arr of user from his premissions arr
export const resourcePolicies_creator_from_resource_with_actions = (resources_with_actions: ResourceWithActions[], allResources: ResourceT[], allActions: ActionT[]): ResourcePolicy[] => {
    let populatedArr: ResourcePolicy[] = []
    //if some permissions has been set for role till now

    for (const resource of allResources) {
        //first we have to extract current resource from role permissionsArr, it might not exist at all, so related_role-resource would be 'null'
        let related_resource_with_actions: ResourceWithActions 

        related_resource_with_actions = resources_with_actions.find(reso => resource.value === reso.value)

        for (const action of allActions) {
            //if current resource exists in role permissions and if it has the current action as well, then the value of is_denied would be true, otherwise it would be false 
            let current_action_is_permitted = related_resource_with_actions?.actions?.find(act => action.value === act.value) ? true : false
            populatedArr.push({
                name: '',
                resource: resource.value,
                action: action.value,
                is_denied: !current_action_is_permitted
            })
        }
    }
    return populatedArr
}






const RolePolicyEditTable: React.FC<RolePolicyEditTablePropsT> = props => {
    const [initialResourcePolicies, setInitialResourcePolicies] = useState<ResourcePolicy[]>(resourcePolicies_creator_from_resource_with_actions(props.policyData.policies,props.allResources,props.allActions))
    const [currentResourcePolicies, setCurrentResourcePolicies] = useState<ResourcePolicy[]>(resourcePolicies_creator_from_resource_with_actions(props.policyData.policies,props.allResources,props.allActions))
    const [confirmedAccessPolicies, setConfirmedAccessPolicies] = useState<ResourcePolicy[]>([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    console.log('initialResourcePolicies   =====>',initialResourcePolicies)
    const wildCard_wildCard_currentResourcePoliciesChanger = (isPermitted: boolean) => {
        setCurrentResourcePolicies(prev => update_wildCard_wildCart_action_permissions_in_resource_policies(prev, isPermitted))
    }
    
    const resource_wildCard_currentResourcePoliciesChanger = (action_value: number,isPermitted: boolean) => {
        setCurrentResourcePolicies(prev => update_wildCard_action_permissions_in_resource_policies(
            {current_resource_policies: prev,direction: DirectionT.action,direction_value: action_value,isPermitted}))
        }
        
    const action_wildCard_currentResourcePoliciesChanger = (resource_value: number,isPermitted: boolean) => {
        setCurrentResourcePolicies(prev => update_wildCard_action_permissions_in_resource_policies(
            {current_resource_policies: prev,direction: DirectionT.resource,direction_value: resource_value,isPermitted}))
    }
            
    const currentResourcePoliciesChanger = (resource_action_id: string,isPermitted: boolean) => {
        setCurrentResourcePolicies(prev => update_action_permission_in_resource_policies_arr_v2(prev,resource_action_id,isPermitted))
    }
    //1- first we have to build our table header
    //first we sort all actions in our desired manner then we create their corresponding tableCell for showing as head of columns
    const sortedActions = props.allActions.sort((firstEl,secondEL) => {
        if(firstEl.value < secondEL.value) { return 1; }
    	if(firstEl.value > secondEL.value) { return -1; }
    	return 0;
    })
    const allActionNameCellsInHeadOfTable = sortedActions.map((action,index) => {
        const desc = action.value !== 1 ? action.name : 'All'
        //we dont want to show name of '*' for action => {name: '*', value: 1}
        return (<td key={`${index}-sajg`}>{desc}</td>)
    })

    const get_related_action_cells_for_a_resource = (resource_value: number) => {
        const related_resource_actions_in_policies = props.policyData.policies.filter(reso => reso.value === resource_value)
        return sortedActions.map((action,index) => {
            const resource_action_id = `${resource_value}__${action.value}`
            //we have to find out whether this resource_action policy exists in the policies arr, to check the checkbox or not
            const related_current_action_permission = extact_action_permission_from_recourcePolicies(currentResourcePolicies,resource_action_id)
            const related_initial_action_permission = extact_action_permission_from_recourcePolicies(initialResourcePolicies,resource_action_id)
            const related_confirmed_action_permission = extact_action_permission_from_recourcePolicies(confirmedAccessPolicies,resource_action_id)
            return (
            <td key={resource_action_id} 
                className={[
                    related_initial_action_permission.is_denied !== related_current_action_permission.is_denied ? changed : 
                    related_confirmed_action_permission ? confirmed : '' ].join(' ')}>
                <Check id={resource_action_id} value={!related_current_action_permission.is_denied} 
                    onChangeHandler={
                        resource_value === 1 && action.value === 1 ? (e) => wildCard_wildCard_currentResourcePoliciesChanger(e.target.checked) :
                        resource_value === 1 && action.value !== 1 ? (e) => resource_wildCard_currentResourcePoliciesChanger(action.value, e.target.checked) :
                        action.value === 1 && resource_value !== 1 ? (e) => action_wildCard_currentResourcePoliciesChanger(resource_value, e.target.checked) :
                        (e) => currentResourcePoliciesChanger(resource_action_id, e.target.checked)
                    }/>
            </td>)
        })
    }

    const rows = props.allResources.map((resource,index) => {
        return (
            <tr className={bodyRow} key={`${index}-sdas`}>
                <td>{index+1}</td>
                <td>{props.policyData.role.name}</td>
                <td>{resource.value !== 1 ? resource.name : 'all'}</td>
                {get_related_action_cells_for_a_resource(resource.value)}
            </tr>
        )
    })

    //it sends all updated access_policies for updating in the server
    const confirmHandler = async (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        //first we have to extract all changed policies from the the currentResourcePolicies
        const changedPolicies = extract_changed_access_policies(currentResourcePolicies, initialResourcePolicies)
        console.log('channgedPolicies ====>',changedPolicies)
        //then we have to change this data to respect the req body form
        const {policies:reduced_size_policies} = modify_req_data_for_update_policies_based_on_backend_instruction_v2(changedPolicies, props.allResources, props.allActions)
        console.log('reduced_size_policies ====>',reduced_size_policies)
        
        const reqBody: AdminEditRoleAccessPolicyReqT = {
            role: props.policyData.role.value,
            access_policies: reduced_size_policies
            // access_policies: changedPolicies.map(changedPolicy => {
            //     return {
            //         name: '',
            //         action: changedPolicy.action,
            //         resource: changedPolicy.resource,
            //         is_denied: changedPolicy.is_denied
            //     }
            // })
        }
        try {
            setLoading(true)
            const res = await adminEditRoleAccessPolicies(props.access_token, reqBody, {dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
                toast.success('policies were updated successfully')
                const server_confirmed_policies = (res.data as AdminEditRoleAccessPolicyResT).access_policies
                //then we have to find the final confirmed policies from the backend res and our initial_policies
                const {final_access_policies, confirmed_policies} = update_local_access_policies_based_on_confirmed_changed_policies_from_the_server_response_v3(changedPolicies,server_confirmed_policies,initialResourcePolicies)
                //then we have set confirmed policies to change the styles
                setConfirmedAccessPolicies(confirmed_policies)
                setInitialResourcePolicies(final_access_policies)
                return props.policyDataUpdater(final_access_policies)   //to make updated_data persistant on page refresh
            }
        }
        catch(err){
            setLoading(false)
        }
    }


    return(
        <form className={rolePolicyEditTable}>
             <div className={confirmBtn}>
                <ButtonA type='primary' children='Confirm changes' onClick={(e) => {confirmHandler(e)}} loading={loading}/>
            </div>
            <div className={tableConatiner}>
                <table className={table}>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Role</td>
                            <td>Resource</td>
                            {
                            allActionNameCellsInHeadOfTable
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                        rows
                        }
                    </tbody>
                </table>
            </div>

    </form>
    )
}



export default RolePolicyEditTable