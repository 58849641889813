import styles from './filterC.module.scss'

import DatePicker from '../datePicker/reactDatePicker'
import ButtonA from '../buttons/buttonA'
import RightImg from '../../assets/images/right.png'
import CalenderImg from '../../assets/images/calender.png'
import AddCharsFormControl from '../formControl/addCharsFormControl/addCharsFormControl'

import FormControl,{SelectContent} from '../formControl/formControl'
import { CharacterT } from '../../utils/models'
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory'


const {filterC, filterItem, filterLabel, filterValue, arrIcon, calIcon, firstChars,secondChars, lang, firstAccount, secondAccount, firstDevice, secondDevice, createdRange, confirmBtn, dateBox} = styles


type FilterProps = {
    createdAt?: {from: Date, to: Date}
    setCreatedAt?: (a: any) => void

    langsFC?: SelectContent
    langValue?: number
    setLangValue?: (e: any, value: number, label: string, object: object) => void

    allChars?: CharacterT[]
    firstCharsValue?: CharacterT[]
    setFirstCharsValue?: (a) => void

    secondCharsValue?: CharacterT[]
    setSecondCharsValue?: (a) => void

    confirmHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
    loading: boolean
}

const Filter:React.FC<FilterProps> = ({
    langsFC, langValue, setLangValue,
    allChars, firstCharsValue, setFirstCharsValue, secondCharsValue, setSecondCharsValue,
    createdAt, setCreatedAt,
    confirmHandler, loading
}) => {
    
    return (
    <div className={filterC}>
   
        {
        createdAt && setCreatedAt ?
        <div className={[filterItem, createdRange].join(' ')}>
            <div className={filterLabel}>created at:</div>
            <div className={filterValue}>
                <div className={dateBox}><DatePicker date={createdAt.from} setDate={(date) => {setCreatedAt(prev => ({...prev,from: date}))}} placeholder="Start date"/></div>
                <img src={RightImg} className={arrIcon}/>
                <div className={dateBox}><DatePicker date={createdAt.to} setDate={(date) => {setCreatedAt(prev => ({...prev, to: date}))}} placeholder="End date"/></div>
                <img src={CalenderImg} className={calIcon}/>
            </div>
        </div>
        :""
        }
        


        {
        langsFC ?
        <div className={[filterItem, lang].join(' ')}>
            <div className={filterLabel}>language:</div>
            <div className={filterValue}>
                <FormControl content={langsFC} value={langValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setLangValue} />
            </div>
        </div>
        :""
        }




        {
        allChars ? 
        <div className={[filterItem, firstChars].join(' ')}>
            <div className={filterLabel}>first character:</div>
            <div className={filterValue}>
                <AddCharsFormControl values={firstCharsValue} setValues={setFirstCharsValue} name="character" options={allChars} isMultiValue={true}/>
            </div>
        </div>
        : 
        ''
        }
        {
        allChars && firstCharsValue.length > 0?
        <div className={[filterItem, secondChars].join(' ')}>
            <div className={filterLabel}>second character:</div>
            <div className={filterValue}>
                <AddCharsFormControl values={secondCharsValue} setValues={setSecondCharsValue} name="character" options={allChars} isMultiValue={true}/>
            </div>
        </div>
        :''
        }
        
        <div className={confirmBtn}>
            <ButtonA children={'filter result'} type='primary' loading={loading} onClick={confirmHandler}/>
        </div>
    </div>
    )
}


export default Filter