import { useEffect, useState } from 'react';
import ButtonA from '../../components/buttons/buttonA';
import { useNavigate } from 'react-router-dom'
import styles from './register.module.scss';
import {useDispatch, UserReduxActionTypesT} from '../../redux/index'
import {PASSWORD_MIN_LENGTH, PATHES} from '../../utils/contants'
import {getConfigs, SendRegisterReqT, sendRegisterRequest, getUserInfo} from '../../utils/requests'
import { toast } from 'react-toastify'
import FormControl,{SelectContent} from '../../components/formControl/formControl';
import {emailX2FC, passwordX2FC, confirmPasswordX2FC, RoleFC_creator} from '../../utils/formControl'
import {validator, ValidatorTypes} from '../../utils/validator'


const {formWrapper, inputBox, email, password, confirmPassword, role, confirmBtnBox, confirmBtn} = styles

const Register = () => { 
  const [roleFC, setRoleFC] = useState<SelectContent>(RoleFC_creator([]))
  const [emailV, setEmailV] = useState('')
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [passV, setPassV] = useState('')
  const [passIsValid, setPssIsValid] = useState(true)
  const [confirmedPassV, setConfirmedPassV] = useState('')
  const [roleV, setRoleV] = useState(0)
  const [roleIsValid, setRoleIsValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errorsObj, setErrorsObj] = useState({email: null, password: null, confirmedPassword: null, role: null})

  const dispatch = useDispatch()
  const navigate = useNavigate()
    
  const roleChanger = (event: any, value: any, label: any, object: any) => {
    setRoleV(+roleFC.options.find(role => role.value === value).value)
  }

  
  //to get the role options from the server at the start
  useEffect(() => {
    const fetchData = async (token) => {
      try {
        const res = await getConfigs(token, {dispatch,navigate,refresh_token: '12312',toast})
        if(res.status === 200){
          setRoleFC(RoleFC_creator(res.data.roles))
          return
        }
      }
      catch(err){}
    }
    fetchData('')
  }, [])


  const confirmHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if(loading) return
    const populatedErrors = {email: null, password: null, confirmedPassword: null, role: null}
    let hasError = false

    const emailValidationResult = validator({type: ValidatorTypes.email, value: emailV})
    const passwordsValidationResult = validator({type: ValidatorTypes.password, value: [passV,confirmedPassV], nChar: PASSWORD_MIN_LENGTH})
    const roleValidationResult = validator({type: ValidatorTypes.select, value: `${roleV}`, defaultSelectValue: '0'})

    if(!emailValidationResult.isValid){
      hasError = true
      populatedErrors.email = emailValidationResult.errs[0]
      setEmailIsValid(false)
    }
    if(!passwordsValidationResult.isValid){
      hasError = true 
      populatedErrors.password = passwordsValidationResult.errs[0]
      setPssIsValid(false)
    }
    if(!roleValidationResult.isValid){
      hasError = true 
      populatedErrors.role = roleValidationResult.errs[0]
      setRoleIsValid(false)
    }
    if(hasError){
      return setErrorsObj(populatedErrors)
    }
    //here we know all inputs are valid
    const reqBody: SendRegisterReqT = {
      email: emailV,
      password: passV,
      role: roleV
    }
    try{
      setLoading(true)
      const res = await sendRegisterRequest(reqBody,{dispatch,navigate,refresh_token: '12312',toast})
      setLoading(false)
      if (res.status === 200) {
        //after successfully registeration of user, we have to refresh inputs
        setEmailV('')
        setPassV('')
        setConfirmedPassV('')
        setRoleV(0)
        return toast.success('you have been registered, wait till you get a confirmation email')
      }
    }
    catch(err){
      setLoading(false)
    }
  };
  


  
  




  return (
    <>
      <form className={formWrapper} onSubmit={confirmHandler} >
        <div className={[inputBox, email].join(' ')}>
          <FormControl content={emailX2FC} value={emailV} setParentValue={setEmailV} isValid={emailIsValid} setIsValid={setEmailIsValid} errorMsg={errorsObj.email}/>
        </div>
        <div className={[inputBox, password].join(' ')}>
          <FormControl content={passwordX2FC} value={passV} setParentValue={setPassV} isValid={passIsValid} setIsValid={setPssIsValid} errorMsg={errorsObj.password}/>
        </div>
        <div className={[inputBox, confirmPassword].join(' ')}>
          <FormControl content={confirmPasswordX2FC} value={confirmedPassV} setParentValue={setConfirmedPassV} isValid={passIsValid} setIsValid={setPssIsValid} errorMsg={errorsObj.password}/>
        </div>
        <div className={[inputBox, role].join(' ')}>
          <FormControl content={roleFC} value={roleV} optionChangeHandler={roleChanger} isValid={roleIsValid} setIsValid={setRoleIsValid} errorMsg={errorsObj.role}/>
        </div>
        <div className={confirmBtnBox}>
          <ButtonA children='Register' onClick={confirmHandler} type="primary" loading={loading} externalClass={confirmBtn}/>
        </div>
      </form>
    </>
  )
}

export default Register
