import { useEffect, useState } from 'react';
import ButtonA from '../../components/buttons/buttonA';
import { useNavigate } from 'react-router-dom'
import styles from './forgotPass.module.scss';
import {useDispatch, UserReduxActionTypesT} from '../../redux/index'
import {PASSWORD_MIN_LENGTH, PATHES} from '../../utils/contants'

import {requestToChangePassword, RequestToChangePasswordReqT} from '../../utils/requests'
import { toast } from 'react-toastify'
import FormControl,{SelectContent} from '../../components/formControl/formControl';
import {emailX2FC, passwordX2FC, confirmPasswordX2FC, RoleFC_creator} from '../../utils/formControl'
import {validator, ValidatorTypes} from '../../utils/validator'


const {formWrapper, inputBox, email, forgotBtnBox, confirmPassword, forgotBtn, confirmBtnBox, confirmBtn} = styles

const ForgotPassword = () => { 
  const [emailV, setEmailV] = useState('')
  const [emailIsValid, setEmailIsValid] = useState(true)

  const [loading, setLoading] = useState(false)
  const [errorObj, setErrorObj] = useState({email: null})

  const dispatch = useDispatch()
  const navigate = useNavigate()
    

  




  const confirmHandler = async (e) => {
    e.preventDefault()
    if(loading) return

    const emailValidationResult = validator({type: ValidatorTypes.email, value: emailV})
    if(!emailValidationResult.isValid){
        setEmailIsValid(false)
        return setErrorObj({email: emailValidationResult.errs[0]})
    }

    const reqBody: RequestToChangePasswordReqT = {
      email: emailV
    }
    try{ 
      setLoading(true)
      const res = await requestToChangePassword(reqBody,{dispatch,navigate,refresh_token: '12321',toast})
      setLoading(false)
      if(res.status === 200){
        toast.success('one generated code has been sent to your email, enter it in the next page')
        navigate({pathname: PATHES.refreshPassword},{state: {from: PATHES.forgotPassword, email: emailV}})
        return
      }
    }
    catch(err){
      setLoading(false)
    }
  }


  
  




  return (
    <>
      <form className={formWrapper} onSubmit={confirmHandler} >
        <div className={[inputBox, email].join(' ')}>
            <FormControl content={emailX2FC} value={emailV} setParentValue={setEmailV} isValid={emailIsValid} setIsValid={setEmailIsValid} errorMsg={errorObj.email}/>
        </div>
        <div className={forgotBtnBox}>
            <ButtonA children='Send verification code' type='primary' loading={loading} onClick={confirmHandler} externalClass={forgotBtn}/>
        </div>
      </form>
    </>
  )
}

export default ForgotPassword
