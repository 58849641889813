import React,{useEffect, useState} from 'react'
import styles from './userEditRole.module.scss'
import FormControl,{SelectContent} from '../../formControl/formControl'
import ButtonA from '../../buttons/buttonA'
import {adminChangeUserRole} from '../../../utils/requests'
import {validator,ValidatorTypes} from '../../../utils/validator'
import {RoleUpdaterArgT} from '../../../pages/userEdit/userEdit'
import {toast} from 'react-toastify'
import {useDispatch} from '../../../redux/index'
import { useNavigate } from 'react-router-dom'

const {userEditRole, role, confirmBtn, item, itemLabel, itemInput  } = styles


type UserEditRoleProps = {
    role: number,
    roleFC: SelectContent,
    access_token: string,
    refresh_token: string
    userId: number
    userDataUpdater: (arg: RoleUpdaterArgT) => void
}


const UserEditRole: React.FC<UserEditRoleProps> = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [roleV, setRoleV] = useState(props.role)
    const [roleIsValid, setRoleIsValid] = useState(true)

    const [loading, setLoading] = useState(false)
    const [errorObj, setErrorObj] = useState({role: null})

    


    const roleChanger = (e: any, value: number, label: string, object: object) => {
        const selectedRole = props.roleFC.options.find(role => role.value === value)
        setRoleV(+selectedRole.value)
    }

    const confirmHandler = async(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if(loading) return
        //there is no need to validate role because role by default is selected and all options are valid
        //but if user hasnt change the role, we dont want to send a req
        if(props.role === roleV) return
        try {
            setLoading(true)
            const res = await adminChangeUserRole(props.access_token, props.userId, roleV, {dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
                toast.success('role was changed successfully')
                props.userDataUpdater({type: 'role',payload: roleV})
                return
            }
            //if an error occured, we want to reverse the value of select back to its initial value comes from list
            setRoleV(props.role)
        }
        catch(err){
            setRoleV(props.role)
            setLoading(false)
        }
    }
   

    return(
        <form className={userEditRole}>
            <div className={[item,role].join(' ')}>
                <div className={itemLabel}>role:</div>
                <div className={itemInput}>
                    <FormControl content={props.roleFC} value={roleV} isValid={roleIsValid} setIsValid={setRoleIsValid} optionChangeHandler={roleChanger} errorMsg={errorObj.role}/>
                </div>
            </div>
            <div className={confirmBtn}>
                <ButtonA type='primary' loading={loading} children='Confirm changes' onClick={(e) => {confirmHandler(e)}}/>
            </div>
        </form>
    )
}


export default UserEditRole