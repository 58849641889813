import styles from './changePassword.module.scss'
import React,{useState} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector} from '../../redux/index'
import { useNavigate, useLocation, Navigate} from 'react-router-dom'
import {PASSWORD_MIN_LENGTH} from '../../utils/contants'
import {PATHES} from '../../utils/contants'
import FormControl from '../../components/formControl/formControl'
import {validator, ValidatorTypes} from '../../utils/validator'
import {oldPasswordFC, newPasswordFC, confirmNewPasswordFC} from '../../utils/formControl'
import { changeProfilePassword, ChangeProfilePasswordReqT } from '../../utils/requests'
import {toast} from 'react-toastify'

import {useDispatch} from '../../redux/index'
import {historyPropertiesExistanceCheck} from '../../utils/utility'

const {profile, container, item, itemLabel, itemInput, oldPass, newPass, confirmPass } = styles

const ChangePassword:React.FC = props => {
    const location = useLocation()
    const from = historyPropertiesExistanceCheck(location, 'from', null)
    const {access_token: reduxAccessToken, refresh_token} = useTypedSelector(state => ({access_token: state.user.access_token, refresh_token: state.user.refresh_token}))
    const dispatch = useDispatch()
    const [oldPassV, setOldPassV] = useState('')
    const [oldPassIsValid, setOldPassIsValid] = useState(true)

    const [newPassV, setNewPassV] = useState('')
    const [newPassIsValid, setNewPassIsValid] = useState(true)

    const [confirmNewPassV,setConfirmNewPassV] = useState('')

    const [errorsObj,setErrorsObj] = useState({oldPassword: null,newPasswords: null})

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    let entranceIsIllegal = false
    if(from !== PATHES.profile){
        entranceIsIllegal = true
    }

    const submitHandler = async () => {
        //if loading is true, it means we have sent a req and doesnt get its res yet, so we have to wait more and we cant send another req
        if(loading){
            return
        }
        let gatheredErrors = {oldPassword: null,newPasswords: null}
        let hasError = false
        const oldPassValidationResult = validator({type: ValidatorTypes.minimumLengthString, value: oldPassV, nChar: PASSWORD_MIN_LENGTH})
        const newPasswordsValidationResult = validator({type: ValidatorTypes.password, value: [newPassV, confirmNewPassV], nChar: PASSWORD_MIN_LENGTH})

        //now we have to checkValidations and set Errors for them
        if(!oldPassValidationResult.isValid){
            gatheredErrors.oldPassword = oldPassValidationResult.errs[0]
            setOldPassIsValid(false)
            hasError = true
        }
        if(!newPasswordsValidationResult.isValid){
            gatheredErrors.newPasswords = newPasswordsValidationResult.errs[0]
            setNewPassIsValid(false)
            hasError = true
        }
        //if both oldPass and newPass is valid, then we have to check if they are same, there is no reason to allow user to update his password
        if(newPasswordsValidationResult.isValid && oldPassValidationResult.isValid && oldPassV === newPassV){
            gatheredErrors.newPasswords = 'your new password must differ from the old one'
            setNewPassIsValid(false)
            hasError = true
        }
        //then we have to exit the func if we have error in some fields
        if(hasError){
            const {oldPassword,newPasswords} = gatheredErrors
            return setErrorsObj({oldPassword,newPasswords})
        }
        //here we know that all inputs have valid values
        const reqBody: ChangeProfilePasswordReqT = {
            current_password: oldPassV,
            new_password: newPassV,
            confirmed_new_password: confirmNewPassV
        }

        try {
            setLoading(true)
            const res = await changeProfilePassword(reduxAccessToken,reqBody, {dispatch, navigate, toast, refresh_token})
            setLoading(false)
            if(res.status === 200){
                toast.success('password has been changed successfully')
                return navigate({pathname: PATHES.profile})
            }
        }
        catch(erro){
            setLoading(false)
        }

    }


    const confirmBtnProps: ButtonAProps = {
        type: 'primary',
        children: 'Confirm changes',
        onClick: submitHandler,
        loading: loading
    }
    const previousPageBtnProps: ButtonAProps = {
        type: 'secondary',
        children: 'Back to profile',
        onClick: () => {navigate({pathname: PATHES.profile})}
    }

    return (
      <Template>
        {
        entranceIsIllegal ? <Navigate to={{pathname: PATHES.home}} replace={true} />
        :
        <div className={profile}>
           <MainPanel title='Your profile' headButtons={[confirmBtnProps,previousPageBtnProps]}>
                <form className={container}>
                    <div className={[item,oldPass].join(' ')}>
                        <div className={itemLabel}>old password</div>
                        <div className={itemInput}>
                            <FormControl content={oldPasswordFC} value={oldPassV} setParentValue={setOldPassV} isValid={oldPassIsValid} setIsValid={setOldPassIsValid} errorMsg={errorsObj.oldPassword}/>
                        </div>
                    </div>
                    <div className={[item,newPass].join(' ')}>
                        <div className={itemLabel}>new password</div>
                        <div className={itemInput}>
                            <FormControl content={newPasswordFC} value={newPassV} setParentValue={setNewPassV} isValid={newPassIsValid} setIsValid={setNewPassIsValid} errorMsg={errorsObj.newPasswords}/>
                        </div>
                    </div>
                    <div className={[item,confirmPass].join(' ')}>
                        <div className={itemLabel}>confirm password</div>
                        <div className={itemInput}>
                            <FormControl content={confirmNewPasswordFC} value={confirmNewPassV} setParentValue={setConfirmNewPassV} isValid={newPassIsValid} setIsValid={setNewPassIsValid} errorMsg={null}/>
                        </div>
                    </div>
                </form>
           </MainPanel>
        </div>
        }
      </Template>
    )
}

export default ChangePassword

