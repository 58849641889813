
import { useState } from 'react';
import DatePicker from 'react-datepicker'
import './reactDatePicker.scss'


type ReactDatePickerProps = {
  date: Date | null
  setDate: (Date) => void
  placeholder: string
}



const ReactDatePicker: React.FC<ReactDatePickerProps> = (props: ReactDatePickerProps) => {
  // const [startDate, setDate] = useState(props.date)
  const [rangeStart, setRangeStart] = useState(new Date)
  const defaultEndDate = new Date()
  defaultEndDate.setDate(defaultEndDate.getDate() + 7)
  const [rangeEnd, setRangeEnd] = useState(defaultEndDate)
  const today = new Date()

  const selectDateHandler = (d) => {
    props.setDate(d)
  }

  const selectStartDate = d => {
    setRangeStart(d)
  }

  const selectEndDate = d => {
    setRangeEnd(d)
  }
  return (
  
      <DatePicker
        dateFormat="yyyy/MM/dd"
        selected={props.date}
        onChange={selectDateHandler}
        placeholderText={props.placeholder} 
        // minDate={today}
        // todayButton={"Today"}

        // onClickOutside={this.datePickerValue}
        // maxDate={new Date()}
        yearDropdownItemNumber={10}
        scrollableYearDropdown={true}
        showYearDropdown
        showMonthDropdown
        disabledKeyboardNavigation/>
       

  
  );
}

export default ReactDatePicker



