import styles from './talkListTable.module.scss'
import { useState ,useEffect} from 'react'
import Filter from '../../filters/filterB'
import { CSVLink} from 'react-csv'
import MobilePagination from '../../pagination/mobilePagination'
import { SelectContent } from '../../formControl/formControl'
import {CharacterT, TalkT} from '../../../utils/models'
import {changeHourOfISODate} from '../../../utils/utility'

import ButtonA from '../../buttons/buttonA'
import {GetTalks, GetTalksResT, GetTalkFiltersT} from '../../../utils/requests'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from '../../../redux'
import {toast} from 'react-toastify'
import {FirstAccountIdFC, SecondAccountIdFC, FirstDeviceIdFC, SecondDeviceIdFC, TalkIdFC} from '../../../utils/formControl'

const {talkListTable, filterBox, tableBox, userTable, headRow, bodyRow, noRecord, paginationBox, exportBtn} = styles


const getThePageTalks = (talkList: TalkT[], page: number) => {
    let TalkList
    if(talkList){
        TalkList = [...talkList]
    }
    else {
        TalkList = []
    }
    const perPage = 10
    const totalPage = Math.ceil(TalkList.length/perPage)
    const skipedTalks = (page-1)*perPage
    TalkList.splice(0,skipedTalks)
    TalkList.splice(perPage)
    return {
        totalPage,
        list: TalkList
    }
}




type TalkListTablePropsT = {
    langFC: SelectContent
    allChars: CharacterT[]
    access_token: string
    refresh_token: string
}

const TalkListTable:React.FC<TalkListTablePropsT> = (props) => {
    const [current_page_talks, set_current_page_talks] = useState<TalkT[]>([])
    const [firstRender, setFirstRender] = useState(true)
    const [talks,setTalks] = useState<TalkT[]>([])
    const [page,setPage] = useState<number | null>(1)
    const [totalPage, setTotalPage] = useState<number | null>(null)
    const [langV,setLangV] = useState(props.langFC.options[0].value as number)
    const [talkIdV, setTalkIdV] = useState('')
    const [firstCharsV,setFirstCharsV] = useState<CharacterT[]>([])
    const [secondCharsV, setSecondCharsV] = useState<CharacterT[]>([])
    const [createdAt,setCreatedAt] = useState<{from: Date | null, to: Date | null}>({from: null, to: null})
    const [loading,setLoading] = useState(false)
    const [confirmedFilterPhrase, setConfirmedFilterPhrase] = useState<GetTalkFiltersT | null>(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()




    useEffect(() => {
        const fetchData = async (access_token) => {
            try {
                setLoading(true)
                const res = await GetTalks({token: access_token},{dispatch,navigate,refresh_token: props.refresh_token,toast})
                setLoading(false)
                if(res.status === 200){
                    const resData = res.data as GetTalksResT
                    setTalks(resData.list)
                    const current_page_result = getThePageTalks(resData.list, 1)
                    set_current_page_talks(current_page_result.list)
                    setTotalPage(current_page_result.totalPage)
                }
            }
            catch(err){
                setLoading(false)
            }
        }
        if(props.access_token){
            fetchData(props.access_token)
            setFirstRender(false)
        }
    },[props.access_token])


    useEffect(() => {
        if(!firstRender){
            const current_page_result = getThePageTalks(talks, page)
            set_current_page_talks(current_page_result.list)
        }
    },[page])

    
    //data we need to export as excel 
    let exportable_data: {
        index: number,
        talk_id: number,
        language: string,
        first_character_id: number,
        second_character_id: number,
        created_at: string
        duration: string
    }[] = []
    let exportable_headers = [
        {key: 'index', label: 'index'},
        {key: 'talk_id', label: 'Talk ID'},
        {key: 'language', label: 'Language'},
        {key: 'first_character_id', label: 'First Character ID'},
        {key: 'second_character_id', label: 'Second Character ID'},
        {key: 'duration', label: 'Duration'},
        {key: 'created_at', label: 'Created At'}]
    





    const fetchData = async (access_token: string,filterPhrase: GetTalkFiltersT) => {
        try {
            setLoading(true)
            const res = await GetTalks({token: access_token,filters: filterPhrase},{dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
                const resData = res.data as GetTalksResT
                const current_page_result = getThePageTalks(resData.list, 1)
                if(resData.list){
                    setTalks(resData.list)
                    set_current_page_talks(current_page_result.list)
                }
                else {
                    setTalks([])
                    set_current_page_talks([])
                }
                setPage(1)
                setTotalPage(current_page_result.totalPage)
            }
        }
        catch(err){
            setLoading(false)
        }
    }


    const confirmHandler = async() => {
        if(loading){
            return
        }
        //we want to validate inputs 
        // console.log('talk id --->',talkIdV)
        // console.log('language --->',langV)
        // console.log('first chars --->',firstCharsV)
        // console.log('second chars --->',secondCharsV)
        // console.log('created at --->',createdAt)

       
        //first we have to populate input values if exists any
        const filteredPhrase: GetTalkFiltersT = {}
      
        if(talkIdV.length > 0){
            filteredPhrase.talk_id = talkIdV

        }
        if(langV !== 0){
            filteredPhrase.language_id = langV.toString()
        }
        if(createdAt && createdAt.from){
            const from = createdAt.from
            filteredPhrase.from = changeHourOfISODate(from, 0, 0, 0)
        }
        if(createdAt && createdAt.to){
            const to = createdAt.to
            filteredPhrase.to = changeHourOfISODate(to, 23, 59, 59)
        }
        if(firstCharsV.length > 0){
            filteredPhrase.character_id = firstCharsV[0].value.toString()
        }
        setConfirmedFilterPhrase(filteredPhrase)
        await fetchData(props.access_token, filteredPhrase)
    }

    const languageChanger = (e: any, value: number, label: string, object: object) => {
        setLangV(value)
    }

   talks.forEach((talkRec,index) => {
    const {talk_id,language_id,first_character_id,second_character_id,created_at,duration} = talkRec
    exportable_data.push({
        index: index+1,
        talk_id,
        language: props.langFC.options.find(lang => lang.value === language_id).label.toString(),
        first_character_id,
        second_character_id,
        duration,
        created_at
    })
   })

    const rows = current_page_talks.map((talkRec,index) => {
        const {talk_id,language_id,first_character_id,second_character_id,created_at,duration} = talkRec
        // exportable_data.push({
        //     index: index+1,
        //     talk_id,
        //     language: props.langFC.options.find(lang => lang.value === language_id).label.toString(),
        //     first_character_id,
        //     second_character_id,
        //     created_at
        // })
        let first_char : CharacterT | undefined
        let second_char: CharacterT | undefined
        first_char = props.allChars.find(char => char.value === first_character_id)
        second_char = props.allChars.find(char => char.value === second_character_id)
        return (
            <tr className={bodyRow} key={`${index}-sdas`}>
                <td>{index+1}</td>
                <td>{talk_id}</td>
                <td>{props.langFC.options.find(lang => lang.value === language_id).label}</td>
                {/* <td>{first_character_id}</td> */}
                <td>{first_char ? first_char.name : 'unknown'}</td>
                {/* <td>{second_character_id}</td> */}
                <td>{second_char ? second_char.name : 'unknown'}</td>
                <td>{duration}</td>
                <td>{created_at}</td>
            </tr>
        )
    })
    return(
    <div className={talkListTable}>
        <div className={filterBox}>
            <Filter allChars={props.allChars} confirmHandler={confirmHandler} loading={loading} //
                langsFC={props.langFC} langValue={langV} setLangValue={languageChanger} //
                firstCharValues={firstCharsV} setFirstCharValues={setFirstCharsV} secondCharValues={secondCharsV} setSecondCharValues={setSecondCharsV} //
                talkIDContent={TalkIdFC} talkIDValue={talkIdV} setTalkIDValue={setTalkIdV} //
                createdAt={createdAt} setCreatedAt={setCreatedAt} //
             />
        </div>
        <div className={tableBox}>
            <div className={exportBtn}>
                <CSVLink data={exportable_data} headers={exportable_headers} filename={"test.csv"} >
                    <ButtonA children={`export entire talk list`} onClick={() => {}} type="secondary"/>
                </CSVLink>
            </div>
            <table className={userTable}>
                <thead>
                    <tr className={headRow}>
                        <td style={{minWidth: '2em'} as React.CSSProperties}></td>
                        <td>Talk ID</td>
                        <td>Language ID</td>
                        <td>First Character ID</td>
                        <td>Second Character ID</td>
                        <td>Duration</td>
                        <td>created at</td>
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={10}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </div>
        <div className={paginationBox}>
            <MobilePagination parentCurrentPage={page} setParentCurrentPage={(page) => {setPage(page)}} totalPageNum={totalPage}/>
        </div>
    </div>

    )
} 


export default TalkListTable