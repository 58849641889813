

import React, { useState, useEffect, useCallback, useRef, lazy, Suspense } from "react";
import Fallback from "./components/fallback/fallback";
import Login from './pages/login/login'
import Home from "./pages/home/home";
import Profile from './pages/profile/profile'
import EditProfile from './pages/editProfile/editProfile'
import ChangePassword from './pages/changePassword/changePassword'
import UserList from './pages/userList/userList'
import UserEdit from './pages/userEdit/userEdit'
import UserCreate from './pages/createUser/userCreate'
import PolicyList from './pages/policyList/policyList'
import PolicyEdit from './pages/policyEdit/policyEdit'
import RefreshPassword from './pages/login/refreshPassword'
import TalkList from "./pages/talkList/talkList";
import { Route, Routes } from 'react-router-dom';
import TalkReportsLanguageCount from './pages/talkReports/languageCount/languageCount'
import TalkReportsLanguageDuration from './pages/talkReports/languageDuration/languageDuration'
import TalkReportsCharacterCount from './pages/talkReports/characterCount/characterCount'
import TalkReportsCharacterDuration from "./pages/talkReports/characterDuration/characterDuration";
import TalkAnother from './pages/talkReports/anotherV2/another'

import { PATHES,SESSION_STORAGE_KEYS} from './utils/contants'
import { makeSureOfExistanceOfEntireStateInSessionStorageANDRedux } from './utils/others'

import { useDispatch, useTypedSelector, UserReduxActionTypesT, ConfigsReduxActionTypesT } from './redux/index'

import styles from './app.module.scss'
import AuthRoute from './components/others/authRoute'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CharacterT, LanguageT } from "./utils/models";



const { app } = styles


function App(props) {
  const dispatch = useDispatch()
  const {user: userReduxState,configs: configsReduxState} = useTypedSelector(state => ({user: state.user, configs: state.configs}))


  //it gets the state from session storage and adds it into redux
  useEffect(() => {
    makeSureOfExistanceOfEntireStateInSessionStorageANDRedux(userReduxState, UserReduxActionTypesT.saveWholeStateFromSessionStorage, SESSION_STORAGE_KEYS.user, dispatch)
    makeSureOfExistanceOfEntireStateInSessionStorageANDRedux(configsReduxState, ConfigsReduxActionTypesT.saveWholeStateFromSessionStorage, SESSION_STORAGE_KEYS.configs, dispatch)
  }, [])

  //we have to show to homePage if user has the token in his redux store
  let userHasTokenToSeeTheHomePage = false
  if (userReduxState.access_token && userReduxState.resources && configsReduxState.allResources?.length > 0) {
    userHasTokenToSeeTheHomePage = true
  }

  return (
    <div className={app}>
      <Suspense fallback={<Fallback />}>
        <Routes>
          {
            //we only want to render the login page if user doesnt have the token
            !userHasTokenToSeeTheHomePage &&
            <>
            <Route path={PATHES.login} element={<Login />} />
            <Route path={PATHES.refreshPassword} element={<RefreshPassword />} />
            <Route path='*' element={<Login />} />
            </>
          }
          {
            userHasTokenToSeeTheHomePage &&
            <>
            <Route path={PATHES.home} element={<AuthRoute key={PATHES.home}><Home /></AuthRoute>} />
            <Route path={PATHES.profile} element={<AuthRoute key={PATHES.profile}><Profile /></AuthRoute>}/>
            <Route path={PATHES.profileEdit} element={<AuthRoute key={PATHES.profileEdit}><EditProfile/></AuthRoute>}/>
            <Route path={PATHES.profileChangePassword} element={<AuthRoute key={PATHES.profileChangePassword}><ChangePassword/></AuthRoute>}/>
            <Route path={PATHES.user} element={<AuthRoute key={PATHES.user}><UserList/></AuthRoute>}/>
            <Route path={PATHES.userEdit} element={<AuthRoute key={PATHES.userEdit}><UserEdit /></AuthRoute>}/> 
            <Route path={PATHES.userCreate} element={<AuthRoute key={PATHES.userCreate}><UserCreate /></AuthRoute>} />
            <Route path={PATHES.access_policy} element={<AuthRoute key={PATHES.access_policy}><PolicyList/></AuthRoute>}/>
            <Route path={PATHES.policyEdit} element={<AuthRoute key={PATHES.policyEdit}><PolicyEdit/></AuthRoute>} />
            <Route path={PATHES.talkList} element={<AuthRoute key={PATHES.talkList}><TalkList/></AuthRoute>} />
            <Route path={PATHES.talkReportsLanguageCount} element={<AuthRoute key={PATHES.talkReportsLanguageCount}><TalkReportsLanguageCount/></AuthRoute>} />
            <Route path={PATHES.talkReportsLanguageDuration} element={<AuthRoute key={PATHES.talkReportsLanguageDuration}><TalkReportsLanguageDuration/></AuthRoute>} />
            <Route path={PATHES.talkReportsCharacterCount} element={<AuthRoute key={PATHES.talkReportsCharacterCount}><TalkReportsCharacterCount/></AuthRoute>} />
            <Route path={PATHES.talkReportsCharacterDuration} element={<AuthRoute key={PATHES.talkReportsCharacterDuration}><TalkReportsCharacterDuration/></AuthRoute>} />
            <Route path={PATHES.talkAnother} element={<AuthRoute key={PATHES.talkAnother}><TalkAnother/></AuthRoute>} />
            </>
          }
        </Routes>
        <ToastContainer />
      </Suspense>
    </div>
  );
}

export default App;
