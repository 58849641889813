import styles from './characterCountTable.module.scss'
import {CharacterCountDataT, TalkReportsLanguageT, CharacterT} from '../../../utils/models'
const { characterCountTable, headRow, bodyRow, noRecord} = styles



type LanguageMacthedCountTablePropsT = {
    data: CharacterCountDataT
}


const CharacterCountTable: React.FC<LanguageMacthedCountTablePropsT> = props => {
    //it create the table head cells dynamically
    const headItems = Object.keys(props.data)
    const headCells = headItems.map((char,index) => <td key={`${index}-tes`}>{char}</td>)

    //to create the rows 
    const rows = headItems.map((firstCharName, index) => {
        let total = 0
        const cells = Object.keys(props.data[firstCharName]).map((secondChar, ind) => {
            total = total + props.data[firstCharName][secondChar]
            return(<td key={`${index}-${ind}-cde`}>{props.data[firstCharName][secondChar]}</td>)
        })

      
        return (<tr className={bodyRow} key={`${index}-sldq`}>
            <td key={`${firstCharName}-${index}`}>{firstCharName}</td>
            <td key={`${firstCharName}-${index}-total`}>{total}</td>
            {cells}
        </tr>)
     
    })

   
    return(
        <>
            <table className={characterCountTable}>
                <thead>
                    <tr className={headRow}>
                        <td key={'character-name-column'}>Character Name</td>
                        <td key={'total'}>Overal Count</td>
                        {headCells}
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={3}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </>
    )
}


export default CharacterCountTable