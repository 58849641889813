import { UserReduxT, UserReduxActionTypesT, UserReduxActionsT } from "./types"
import { SESSION_STORAGE_KEYS } from '../../utils/contants'

const initialState: UserReduxT = {
  user_id: null,
  access_token: null,
  refresh_token: null,
  email: null,
  phoneNumber: null,
  firstName: null,
  lastName: null,
  userName: null,
  role: null,
  resources: [],
  createdAt: null,
  updatedAt: null,

}
const userReducer = (state = initialState, action: UserReduxActionsT): UserReduxT => {
  switch (action.type) {

    case UserReduxActionTypesT.createAuthToken: {
     
      const newState: UserReduxT = {
        ...state,
        access_token: action.payload.tokens.access_token,
        refresh_token: action.payload.tokens.refresh_token,
        resources: action.payload.permissions
      }
      //saving into the sessionStorage
      sessionStorage.setItem(SESSION_STORAGE_KEYS.user, JSON.stringify(newState))
      return newState
    }


    case UserReduxActionTypesT.appendUserInfo: {
      // console.log('action.payload =====>',action.payload)
      const newState: UserReduxT = {
        ...state,
        user_id: action.payload.id,
        userName: action.payload.username,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        email: action.payload.email,
        phoneNumber: action.payload.phone_number,
        role: action.payload.role,
        createdAt: action.payload.created_at,
        updatedAt: action.payload.updated_at,
      }
      //saving into the sessionStorage
      sessionStorage.setItem(SESSION_STORAGE_KEYS.user, JSON.stringify(newState))
      return newState
    }


    case UserReduxActionTypesT.saveWholeStateFromSessionStorage: {
      return { ...action.payload }
    }


    case UserReduxActionTypesT.updateTokens: {
      const newState: UserReduxT = {
        ...state,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token
      }
      //save into session storage
      sessionStorage.setItem(SESSION_STORAGE_KEYS.user, JSON.stringify(newState))
      return newState
    }

    case UserReduxActionTypesT.removeAuthUser: {
      const newState = initialState
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.user)
      return newState
    }

    case UserReduxActionTypesT.updateUserInfo: {
      const newState = {
        ...state,
        email: action.payload?.email ? action.payload.email : state.email,
        phoneNumber: action.payload?.phoneNumber ? action.payload.phoneNumber : state.phoneNumber,
        firstName: action.payload?.firstName ? action.payload.firstName : state.firstName,
        lastName: action.payload?.lastName ? action.payload.lastName : state.lastName,
        userName: action.payload?.userName ? action.payload.userName : state.userName,
        role: action.payload?.role ? action.payload.role : state.role,
        resources: action.payload?.resources ? action.payload.resources : state.resources,
      }
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.user)
      return newState
    }

    default:
      return state

  }
}
export default userReducer




