import { RoleT, ResourceWithActions, BackendUserT } from '../../utils/models'
import {LoginResponseT} from '../../utils/requests'

//type of action types
export enum UserReduxActionTypesT {
    createAuthToken = 'CREATE_AUTH_TOKEN',
    appendUserInfo = 'APPEND_USER_INFO',
    removeAuthUser = 'REMOVE_AUTH_USER',
    saveWholeStateFromSessionStorage = 'SAVE_WHOLE_USER_FROM_SESION_STORAGE',
    updateTokens = 'UPDATE_TOKENS',
    updateUserInfo = 'UPDATE_USER_INFO'
}

//type of each individual actions
type Action_Create_Token_T = {
    type: UserReduxActionTypesT.createAuthToken,
    payload: LoginResponseT
}

type Action_Append_User_Info_T = {
    type: UserReduxActionTypesT.appendUserInfo,
    payload: BackendUserT
}

type Action_Save_whole_User_FROM_SESSION_STORAGE_T = {
    type: UserReduxActionTypesT.saveWholeStateFromSessionStorage,
    payload: UserReduxT
}

type Action_Update_tokens_T = {
    type: UserReduxActionTypesT.updateTokens,
    payload: {
        access_token: string
        refresh_token: string
    }
}

type Action_Remove_Auth_User_T = {
    type: UserReduxActionTypesT.removeAuthUser
}

type Action_Update_User_Info_T = {
    type: UserReduxActionTypesT.updateUserInfo,
    payload: {
        firstName?: string
        lastName?: string
        email?: string
        phoneNumber?: string
        userName?: string
        role?: number
        resources?: ResourceWithActions[]
        
    }
}

//type of all actions
export type UserReduxActionsT = Action_Create_Token_T | Action_Append_User_Info_T | Action_Save_whole_User_FROM_SESSION_STORAGE_T |
    Action_Update_tokens_T | Action_Remove_Auth_User_T | Action_Update_User_Info_T


//type of redux state
export type UserReduxT = {
    user_id: null | number
    access_token: null | string
    refresh_token: null | string
    email: null | string
    phoneNumber: null | string
    firstName: null | string
    lastName: null | string
    userName: null | string
    role: number
    resources: ResourceWithActions[]
    createdAt: null | string
    updatedAt: null | string
}