
import styles from './userEditStatus.module.scss'

import React,{useState} from 'react'
import FormControl,{SelectContent} from '../../formControl/formControl'
import ButtonA from '../../buttons/buttonA'
import {adminChangeUserStatus} from '../../../utils/requests'
import {toast} from 'react-toastify'
import {StatusUpdatorArgT} from '../../../pages/userEdit/userEdit'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from '../../../redux/index'

const {userEditStatus, status, confirmBtn, item, itemLabel, itemInput  } = styles


type UserEditStatusProps = {
    status: number,
    statusFC: SelectContent
    access_token: string
    refresh_token: string
    userId: number
    userDataUpdater: (arg: StatusUpdatorArgT) => void
}




const UserEditStatus: React.FC<UserEditStatusProps> = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [statusV, setStatusV] = useState(props.status)
    const [statusIsValid, setStatusIsValid] = useState(true)

    const [loading, setLoading] = useState(false)
    const [errorObj, setErrorObj] = useState({status: null})

  
    const statusChanger = (e: any, value: number, label: string, object: object) => {
        const selectedRole = props.statusFC.options.find(role => role.value === value)
        setStatusV(+selectedRole.value)
    }

    const confirmHandler = async(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if(loading) return
        //there is no need to validate status because status by default is selected and all options are valid
        //but if user hasnt change the status, we dont want to send a req
        if(props.status === statusV) return
        try {
            setLoading(true)
            const res = await adminChangeUserStatus(props.access_token, props.userId, statusV, {dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
                toast.success('status was changed successfully')
                props.userDataUpdater({type: 'status', payload: statusV})
                return
            }
            //if an error occured, we want to reverse the value of select back to its initial value comes from list
            setStatusV(props.status)
        }
        catch(err){
            setStatusV(props.status)
            setLoading(false)
        }
    }

 


    return(
        <form className={userEditStatus}>
            <div className={[item,status].join(' ')}>
                <div className={itemLabel}>status:</div>
                <div className={itemInput}>
                    <FormControl content={props.statusFC} value={statusV} isValid={statusIsValid} setIsValid={setStatusIsValid} optionChangeHandler={statusChanger} errorMsg={errorObj.status}/>
                </div>
            </div>
    
            <div className={confirmBtn}>
                <ButtonA type='primary' loading={loading} children='Confirm changes' onClick={(e) => {confirmHandler(e)}}/>
            </div>
        </form>
    )
}


export default UserEditStatus