import styles from './userCreate.module.scss'
import React,{useState, useEffect} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector, useDispatch} from '../../redux/index'
import {toast} from 'react-toastify'
import {adminCreateNewUser, AdminCreateNewUserReqBodyT} from '../../utils/requests'
import { useNavigate } from 'react-router-dom'
import {NAME_MIN_LENGTH, PATHES} from '../../utils/contants'
import {PASSWORD_MIN_LENGTH, USERNAME_MIN_LENGTH} from '../../utils/contants'
import {Langs, validator,ValidatorTypes} from '../../utils/validator'
import FormControl, {InputTypes, SelectContent} from '../../components/formControl/formControl'
import {firstNameFC,lastNameFC,emailFC,RoleFC_creator,newPasswordFC,confirmNewPasswordFC,phoneNumberFC,userNameFC} from '../../utils/formControl'

const {userCreate, container, item, itemLabel, itemInput, firstName, lastName, userName, phoneNumber, email, password, confirmPassword, role } = styles

const UserCreate:React.FC = props => {
    const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [firstNameV, setFirstNameV] = useState('')
    const [firstNameIsValid, setFirstNameIsValid] = useState(true)

    const [lastNameV, setLastNameV] = useState('')
    const [lastNameIsValid, setLastNameIsValid] = useState(true)

    const [userNameV, setUserNameV] = useState('')
    const [userNameIsValid, setUserNameIsValid] = useState(true)

    const [emailV, setEmailV] = useState('')
    const [emailIsValid, setEmailIsValid] = useState(true)

    const [phoneNumberV, setPhoneNumberV] = useState('')
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true)

    const [roleV, setRoleV] = useState(0)
    const [roleFC,setRoleFC] = useState<SelectContent | null>({type: InputTypes.select, name: 'role', options: [{label: 'choose one role', value: 0}]})
    const [roleIsValid, setRoleIsValid] = useState(true)

    const [passV, setPassV] = useState('')
    const [passIsValid, setPassIsValid] = useState(true)

    const [confirmPassV, setConfirmPassV] = useState('')


    const [loading, setLoading] = useState(false)
    const [errorsObj, setErrorsObj] = useState({firstName: null, lastName: null, userName: null, email: null, phoneNumber: null, role: null,password: null, confirmPassword: null})


    //to put all dropdown options in role select
    useEffect(() => {
      if(configRedux.allRoles.length > 0){
        const roleDropdownConfigs = RoleFC_creator(configRedux.allRoles)
        setRoleFC(roleDropdownConfigs)
      }
    },[configRedux])

    const roleChanger = (e: any, value: number, label: string, object: object) => {
      const selectedRole = roleFC.options.find(role => role.value === value)
      setRoleV(+selectedRole.value)
      setRoleIsValid(true)
    }


    const submitHandler = async () => {
      if(loading){
        //we dont want to send another req if we have sent a req already and we havent get its response yet
        return
      }
      //here we can validate user inputs  ===> email, password, role   are essetials
      //but we force user to enter userName, firstName, lastName, phoneNumber as well from the client-side
      const populatedErrors = {firstName: null, lastName: null, userName: null, email: null, phoneNumber: null, role: null,password: null, confirmPassword: null}
      let hasError = false
      const passwordsValidationResult = validator({type: ValidatorTypes.password, value: [passV,confirmPassV], nChar: PASSWORD_MIN_LENGTH})
      const emailValidationResult = validator({type: ValidatorTypes.email, value: emailV})
      const roleValidationResult = validator({type: ValidatorTypes.select, value: roleV.toString(),defaultSelectValue: '0'})
      const userNameValidationResult = validator({type: ValidatorTypes.minimumLengthString, value: userNameV, nChar: USERNAME_MIN_LENGTH})
      const phoneNumberValidationResult = validator({type: ValidatorTypes.phoneNumber, value: phoneNumberV, nChar: 11})
      const firstNameValidationResult = validator({type: ValidatorTypes.name, lang: Langs.en, value: firstNameV, nChar: NAME_MIN_LENGTH})
      const lastNameValidationResult = validator({type: ValidatorTypes.name, lang: Langs.en, value: lastNameV, nChar: NAME_MIN_LENGTH})
      
      if(!passwordsValidationResult.isValid){
        setPassIsValid(false)
        populatedErrors.password = passwordsValidationResult.errs[0]
        hasError = true
      }
      if(!emailValidationResult.isValid){
        setEmailIsValid(false)
        populatedErrors.email = emailValidationResult.errs[0]
        hasError = true
      }
      if(!roleValidationResult.isValid){
        setRoleIsValid(false)
        populatedErrors.role = roleValidationResult.errs[0]
        hasError = true
      }
      if(!userNameValidationResult.isValid){
        setUserNameIsValid(false)
        populatedErrors.userName = userNameValidationResult.errs[0]
        hasError = true
      }
      if(!phoneNumberValidationResult.isValid){
        setPhoneNumberIsValid(false)
        populatedErrors.phoneNumber = phoneNumberValidationResult.errs[0]
        hasError = true
      }
      if(!firstNameValidationResult.isValid){
        setFirstNameIsValid(false)
        populatedErrors.firstName = firstNameValidationResult.errs[0]
        hasError = true
      }
      if(!lastNameValidationResult.isValid){
        setLastNameIsValid(false)
        populatedErrors.lastName = lastNameValidationResult.errs[0]
        hasError = true
      }
      //if any error occurs, we dont want to continue and send req
      if(hasError){
        return setErrorsObj(populatedErrors)
      }

      try {
        const reqBody: AdminCreateNewUserReqBodyT = {
          first_name: firstNameV,
          last_name: lastNameV,
          email: emailV,
          password: passV,
          phone_number: phoneNumberV,
          role: roleV,
          username: userNameV
        }
        setLoading(true)
        const res = await adminCreateNewUser(userRedux.access_token, reqBody, {dispatch,navigate,refresh_token: userRedux.refresh_token,toast})
        setLoading(false)  //after recieving res, we have to set loading to false
        if(res.status === 200){
          navigate({pathname: PATHES.user})
          return toast.success('user was created successfully')
        }
      }
      catch(err){
        setLoading(false) 
      }

    }


    const confirmBtnProps: ButtonAProps = {
        type: 'primary',
        children: 'Confirm',
        onClick: submitHandler,
        loading: loading
    }
    const previousPageBtn: ButtonAProps = {
      type: 'secondary',
      children: 'Back to previous page',
      onClick: () => {navigate({pathname: PATHES.user})}
    }




    return (
      <Template>
        <div className={userCreate}>
           <MainPanel title='Create a new user' headButtons={[previousPageBtn,confirmBtnProps]}>
              <form className={container} style={{width: '100%'} as React.CSSProperties}>
                <input type={'text'} style={{display: 'none'} as React.CSSProperties}/>
                    <div className={[item,firstName].join(' ')}>
                      <div className={itemLabel}>first name</div>
                      <div className={itemInput}>
                        <FormControl content={firstNameFC} value={firstNameV} setParentValue={setFirstNameV} isValid={firstNameIsValid} setIsValid={setFirstNameIsValid} errorMsg={errorsObj.firstName}/>
                      </div>
                    </div>
                    <div className={[item,lastName].join(' ')}>
                      <div className={itemLabel}>last name</div>
                      <div className={itemInput}>
                        <FormControl content={lastNameFC} value={lastNameV} setParentValue={setLastNameV} isValid={lastNameIsValid} setIsValid={setLastNameIsValid} errorMsg={errorsObj.lastName}/>
                      </div>
                    </div>
                    <div className={[item,email].join(' ')}>
                      <div className={itemLabel}>email</div>
                      <div className={itemInput}>
                        <FormControl content={emailFC} value={emailV} setParentValue={setEmailV} isValid={emailIsValid} setIsValid={setEmailIsValid} errorMsg={errorsObj.email}/>
                      </div>
                    </div>
                    <div className={[item,userName].join(' ')}>
                      <div className={itemLabel}>user name</div>
                      <div className={itemInput}>
                        <FormControl content={userNameFC} value={userNameV} setParentValue={setUserNameV} isValid={userNameIsValid} setIsValid={setUserNameIsValid} errorMsg={errorsObj.userName}/>
                      </div>
                    </div>
                    <div className={[item,phoneNumber].join(' ')}>
                      <div className={itemLabel}>phone number</div>
                      <div className={itemInput}>
                        <FormControl content={phoneNumberFC} value={phoneNumberV} setParentValue={setPhoneNumberV} isValid={phoneNumberIsValid} setIsValid={setPhoneNumberIsValid} errorMsg={errorsObj.phoneNumber}/>
                      </div>
                    </div>
                    <div className={[item,password].join(' ')}>
                      <div className={itemLabel}>password</div>
                      <div className={itemInput}>
                        <FormControl content={newPasswordFC} value={passV} setParentValue={setPassV} isValid={passIsValid} setIsValid={setPassIsValid} errorMsg={errorsObj.password}/>
                      </div>
                    </div>
                    <div className={[item,confirmPassword].join(' ')}>
                      <div className={itemLabel}>confirm password</div>
                      <div className={itemInput}>
                        <FormControl content={confirmNewPasswordFC} value={confirmPassV} setParentValue={setConfirmPassV} isValid={passIsValid} setIsValid={setPassIsValid} errorMsg={errorsObj.confirmPassword}/>
                      </div>
                    </div>
                    <div className={[item,role].join(' ')}>
                      <div className={itemLabel}>role</div>
                      <div className={itemInput}>
                        <FormControl content={roleFC} value={roleV} isValid={roleIsValid} setIsValid={setRoleIsValid} optionChangeHandler={roleChanger} errorMsg={errorsObj.role}/>
                      </div>
                    </div>
                </form>
           </MainPanel>
        </div>
      </Template>
    )
}

export default UserCreate

