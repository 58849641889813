import CharacterCountTable from "../../tables/characterCountTable/characterCountTable"
import {TalkReportsLanguageT, CharacterT, TalkReprtDataForCharacterChart, ReconstructedTalkReportsDataT} from '../../../utils/models'
import styles from './characterDuration.module.scss'
import StackedBarChart from "../../charts/stackedBarChart/stackedBarChart"
import Tabs from "../../tab/tabs"
import { useState } from 'react'
import CharacterDurationTable from "../../tables/characterDurationTable/characterDurationTable"

import { recontruct_talk_data_for_characters_chart, recontruct_talk_data_for_characters_table } from "../../../utils/others"

const {characterCount, head, body } = styles

type CharacterDurationPropsT = {
    data: ReconstructedTalkReportsDataT[]
    allChars: CharacterT[]
}




const CharacterDuration: React.FC<CharacterDurationPropsT> = (props) => {
    const [selectedTabId, setSelectedTabId] = useState<number>(0)
     

    const reconstructed_data_for_table = recontruct_talk_data_for_characters_table(props.data, props.allChars, 'duration')
    const recontrcuted_data_for_chart = recontruct_talk_data_for_characters_chart(reconstructed_data_for_table)
    return (
        <div className={characterCount}>
            <div className={head}>
                Characters's Duration
            </div>
            <div className={body}>
                <Tabs components={[
                    <CharacterDurationTable data={reconstructed_data_for_table}/>,
                    <StackedBarChart data={recontrcuted_data_for_chart} title='Matched Character Duration'/>
                ]} 
                selectedTabId={selectedTabId} 
                data={[
                    {id: 0, title: 'table'},
                    {id: 1, title: 'chart'},
                ]} 
                tabChanger={(tabId) => setSelectedTabId(tabId)}/>
            </div>
        </div>
    )
}



export default CharacterDuration