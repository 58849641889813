//in use

import React from 'react'
import styles from './mobilePagination.module.scss'
const {mobilePagination,item,first,last,prev,next,current,clickable} = styles

type MobilePAginationProps = {
    parentCurrentPage: number
    setParentCurrentPage: (a: number) => void
    totalPageNum: number
}

const MobilePagination: React.FC<MobilePAginationProps> = (props) => {
    let items = [];
    let currentPage = props.parentCurrentPage
    let nextPage, previousPage

    //if totalPageNum is more than 0 we want to do this calculation to prevent getting errors
    if(props.totalPageNum !== 0){
        if(currentPage+1 <= props.totalPageNum) {
            nextPage = currentPage+1
        }
        else {
            nextPage = currentPage
        }
    
    
        if (currentPage - 1 >= 1) {
            previousPage = currentPage-1
        }
        else {
            previousPage = currentPage
        }
       
        items.push(<div className={[item, first,clickable].join(' ')} onClick={() => props.setParentCurrentPage(1)} key="123tvd1"></div>)
        
        items.push(<div className={[item, prev,clickable].join(' ')} onClick={() => props.setParentCurrentPage(previousPage)} key="123tvd2"></div>)
        
        items.push(
        <div className={[item,current].join(' ')} key="123tvd3">
            <span>{props.parentCurrentPage}</span>
            <span>/</span>
            <span>{props.totalPageNum}</span>
        </div>)
        
        items.push(<div className={[item, next,clickable].join(' ')} onClick={() => props.setParentCurrentPage(nextPage)}  key="123tvd4"></div>)
        
        items.push(<div className={[item,last,clickable].join(' ')} onClick={() => props.setParentCurrentPage(props.totalPageNum)} key="123tvd5"></div>)
        
    }
    //when totalPageNum is 0 ,we dont need to navigate to other pages
    else {
        items.push(<div className={[item, first,clickable].join(' ')} key="123tvd6"></div>) 
        items.push(<div className={[item, prev,clickable].join(' ')} key="123tvd7"></div>)
        items.push(
            <div className={[item,current].join(' ')} key="123tvd8">
                <span>{0}</span>
                <span>/</span>
                <span>{0}</span>
            </div>)
        items.push(<div className={[item, next,clickable].join(' ')} key="123tvd9"></div>)
        items.push(<div className={[item, last,clickable].join(' ')} key="123tvd10"></div>)
    }

    return (
    <div className={mobilePagination}>
        {items}   
    </div>
    )
}

export default MobilePagination
