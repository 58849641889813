import styles from './talkAnother.module.scss'
import {TalkAnotherT, TalkReportsLanguageT} from '../../../utils/models'
const { languageDurationTable, headRow, bodyRow, noRecord} = styles

type LanguageDurationTablePropsT = {
    data: TalkAnotherT[]
}



const TalkAnotherTable: React.FC<LanguageDurationTablePropsT> = props => {
    const sortedData = props.data.sort((firstRec,secRec) => {
        if(firstRec.waiting_time > secRec.waiting_time) { return -1; }
        if(firstRec.waiting_time < secRec.waiting_time) { return 1; }
        return 0;
    })

    const rows = sortedData.map((record, index) => {
  
        return (
        <tr className={bodyRow} key={`${index}-sqws`}>
            <td>{index+1}</td>
            <td>{record.language_id}</td>
            <td>{record.account_id}</td>
            <td>{record.character_id}</td>
            <td>{record.waiting_time}</td>
        </tr>
        )
    })

    return(
        <>
            <table className={languageDurationTable}>
                <thead>
                    <tr className={headRow}>
                        <td></td>
                        <td>Language</td>
                        <td>Account Id</td>
                        <td>Character Id</td>
                        <td>Waiting Time</td>
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={5}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </>
    )
}


export default TalkAnotherTable