import Template from '../../components/template/template'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import { useState, useEffect } from 'react'
import SpinnerB from '../../components/spinner/spinnerB'
import TalkListTable from '../../components/tables/talkListTable/talkListTable'
import styles from './talkList.module.scss'
import { useTypedSelector, useDispatch } from '../../redux'
import {SelectContent} from '../../components/formControl/formControl'
import {LangT, CharacterT} from '../../utils/models'
import {langFC_creator} from '../../utils/formControl'
import {GetCharacters,GetCharactersResT, GetLangs, GetLangsResT} from '../../utils/requests'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { PATHES, static_token } from '../../utils/contants'
import STATIC_DATA from '../../utils/staticData.json'
const {talkList, container,} = styles

const TalkList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [langFC, setLangFC] = useState<SelectContent | null>(null)
  const [allChars, setAllChars] = useState<CharacterT[] | null>(null)
  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [loading, setLoading] = useState(false)

  //we need to grab all langs and all chars from backend to populate the local state which are needed to filtering the talk records
  useEffect(() => {
    // const fetchChars = async (access_token: string,refresh_token: string) => {
    //   try{
    //     const res = await GetCharacters(access_token,{dispatch,navigate,refresh_token,toast})
    //     if(res.status === 200){
    //       const resData = res.data as GetCharactersResT
    //       setAllChars(resData.list)
    //       //if we could get the chars we'll return true to mark this function to be successful
    //       return true
    //     }
    //   }
    //   catch(err){}
    // }
    // const fetchLangs = async (access_token: string, refresh_token: string) => {
    //   try{
    //     const res = await GetLangs(access_token, {dispatch,navigate,refresh_token,toast})
    //     if(res.status === 200){
    //       const resData = res.data as GetLangsResT
    //       setLangFC(langFC_creator(resData.list))
    //       //if we could get the langs we'll return true to mark this function to be successful
    //       return true
    //     }
    //   }
    //   catch(err){}
    // }
    // const fetchData = async (access_token: string, refresh_token: string) => {
    //   try{
    //     const res = await fetchChars(access_token,refresh_token)
    //     if(res){
    //       //if we could get the chars, then we have to send another req to get langs
    //       setLoading(false)
    //     }
    //   }
    //   catch(err){
    //     //if we couldnt get the required data, then we have to redirect user to the homepage
    //     navigate({pathname: PATHES.home},{replace: true})
    //   }
    // }
    // if(userRedux.access_token && userRedux.refresh_token){
    //   fetchData(userRedux.access_token,userRedux.refresh_token)
    // }
  },[userRedux])



  //configs based on roles and status comes from redux for dropdown
  useEffect(() => {
    if(configRedux.allLanguages.length > 0){
      setLangFC(langFC_creator(configRedux.allLanguages))
    }
    if(configRedux.allCharacters.length > 0){
      setAllChars(configRedux.allCharacters)
    }
 
  },[configRedux])



  return(
    <Template>
      <div className={talkList}>
        {
        // !loading && langFC && allChars ?
        !loading && langFC ?
        <MainPanel title='Talks' headButtons={[]}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
            <TalkListTable langFC={langFC} allChars={allChars} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} />
          </div>
        </MainPanel>
        :
        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          <SpinnerB/>
        </div>
        }
      </div>
  </Template>  
  )
}



export default TalkList