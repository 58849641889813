import styles from './policyEdit.module.scss'
import React, {useState, useEffect} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector, ConfigsReduxT} from '../../redux/index'
import {RoleT} from '../../utils/models'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import {PATHES} from '../../utils/contants'
import {historyPropertiesExistanceCheck,RouterLocationT} from '../../utils/utility'
import {BackendRoleBasedAccessPolicyT, ResourcePolicy, ResourceWithActions} from '../../utils/models'
import SpinnerB from '../../components/spinner/spinnerB'
import {Role_WithoutDefaultOption_FC_creator, UserStatus_WithoutDefaultOption_FC_creator, RoleFC_creator,UserStatusFC_creator} from '../../utils/formControl'
import RolePolicyEditTable from '../../components/tables/rolePolicyEditTable/rolePolicyEditTable'
import { toast } from 'react-toastify'
import { bindActionCreators } from 'redux'

const {policyEdit, container,  } = styles


export type PoliciesUpdatorArgT = {
  type: 'policies',
  payload: ResourcePolicy[]
}







export type PolicyDataT = {
  policies: ResourceWithActions[] 
  role: RoleT
}


const PolicyEdit:React.FC = props => {

  const location = useLocation()
  const policyData: PolicyDataT = historyPropertiesExistanceCheck(location, 'policyData', null)
  const from = historyPropertiesExistanceCheck(location,'from',null)
  const {user: userRedux, configs: configsRedux} = useTypedSelector(state => ({user: state.user,configs: state.configs}))
  const navigate = useNavigate()

  //to put all dropdown options in role select and status select


  // console.log('policyData ====>', policyData)
  // console.log('from ====>', from)
    //if user doesnt come from '/user' page, is not allowed to see this page, so we have to redirect hime to the home
    let illegalAccess = false
    if(from !== PATHES.policyList){
      illegalAccess = true
    }


  const policyDataUpdator = (updated_policies: ResourcePolicy[]) => {
    let final_policy_data: PolicyDataT 
    let final_policies: ResourceWithActions[] = []

    for(const resource of configsRedux.allResources){
      //then we have to extract resources
      const related_resource_policies = updated_policies.filter(policy => policy.resource === resource.value && !policy.is_denied)
      final_policies.push({
        name: resource.name,
        value: resource.value,
        actions: related_resource_policies.map(policy => {
          return {
            value: policy.action,
            name: configsRedux.allActions.find(ac => ac.value === policy.action).name
          }
        })
      })
    }
    final_policy_data = {policies: final_policies, role: policyData.role}
    navigate({pathname: PATHES.policyEdit},{state: {from: PATHES.policyList, policyData: final_policy_data}})
    
  }


   


 


  const previousPageBtnProps: ButtonAProps = {
    type: 'secondary',
    children: 'Back to previous page',
    onClick: () => {navigate({pathname: PATHES.policyList})}
  }





  



    return (
      <>
        {
          illegalAccess ?
          <Navigate to={{pathname: PATHES.home}} replace={true}/>
          :
          userRedux?.access_token && configsRedux?.allActions?.length > 0  ?
          <Template>
            <div className={policyEdit}>
              <MainPanel title='User edit' headButtons={[previousPageBtnProps]}>
                <div className={container}>
                    <RolePolicyEditTable allActions={configsRedux.allActions} allResources={configsRedux.allResources} policyData={policyData} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} policyDataUpdater={policyDataUpdator}/>
                </div>
              </MainPanel>
            </div>
          </Template>
          :
          <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <SpinnerB/>
          </div>
        }
      </>
    )
}

export default PolicyEdit

