
/* eslint-disable */
import React from 'react';
import styles from './refresh.module.scss'
import logo from '../../assets/images/logo1.svg'
import { useState, useEffect } from 'react';
import { UserReduxActionTypesT, ConfigsReduxActionTypesT} from '../../redux/index'
import {RefreshPasswordReqT,refreshPassword} from '../../utils/requests'
import { useDispatch as _useDispatch} from '../../redux/index'
import {LoginResponseT} from '../../utils/requests'
import {useNavigate, useLocation, Navigate} from 'react-router-dom'
import {PATHES} from '../../utils/contants'
import {toast} from 'react-toastify'
import { historyPropertiesExistanceCheck } from '../../utils/utility';
import {emailX2FC, confirmPasswordX2FC, passwordX2FC, VerificationCodeFC} from '../../utils/formControl'
import FormControl from '../../components/formControl/formControl';
import ButtonA from '../../components/buttons/buttonA';
import {validator, ValidatorTypes} from '../../utils/validator'
import ForgotPassword from './forgotPass';


const {pageWrapper, refershPasswordWrapper, logoWrapper, formWrapper, inputBox, confirmPassword, password, refreshBtn,refreshBtnBox, code, backBtn, backBtnBox} = styles



const Register: React.FC = () => {

    const location = useLocation()
    const from = historyPropertiesExistanceCheck(location,'from',null)
    const email = historyPropertiesExistanceCheck(location,'email',null)
    const [verificationCodeV, setVerificationCode] = useState('')
    const [verificationCodeIsValid,setVerificationCodeIsValid] = useState(true)
    const [passV, setPassV] = useState('')
    const [passIsValid, setPassIsValid] = useState(true)
    const [confirmPassV, setConfirmPassV] = useState('')
    const [confirmPassIsValid, setConfirmPassIsValid] = useState(true)
    const [errorsObj, setErrorsObj] = useState({code: null,password: null})
    const [loading, setLoading] = useState(false)
    const dispatch = _useDispatch()
    const navigate = useNavigate()

    //to identify if user has entered to this page from a forgotpass page
    let entranceIsIllegal = false
    if(from !== PATHES.forgotPassword){
      entranceIsIllegal = true
    }


  const confirmHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(loading) return

    const populatedErrors = {code: null, password: null}
    let hasError = false
    const veificationCodeValidationResult = validator({type: ValidatorTypes.justNotEmpty, value: verificationCodeV})
    const passwordsValidationResult = validator({type: ValidatorTypes.password, value: [passV, confirmPassV],nChar: 8})
    if(!veificationCodeValidationResult.isValid){
      setVerificationCodeIsValid(false)
      populatedErrors.code = veificationCodeValidationResult.errs[0]
      hasError = true
    }
    if(!passwordsValidationResult.isValid){
      setPassIsValid(false)
      setConfirmPassIsValid(false)
      populatedErrors.password = passwordsValidationResult.errs[0]
      hasError = true
    }

    if(hasError){
      return setErrorsObj(populatedErrors)
    }
    //if we dont have any error, we have to send a req to the backend
    const reqBody: RefreshPasswordReqT = {
      code: verificationCodeV,
      email: email,
      password: passV
    }
    try {
      setLoading(true)
      const res = await refreshPassword(reqBody, {dispatch, navigate, refresh_token: '213123', toast})
      setLoading(false)
      if(res.status === 200){
        toast.success('your new password is registered, you can login with it now')
        return navigate({pathname: PATHES.login})
      }
    }
    catch(err){
      setLoading(false)
    }
  };





  return (
  <>
    {
      entranceIsIllegal && 
      <Navigate to={{pathname: PATHES.login}} replace={true}/>
    }
    <div className={pageWrapper} >
      <div className={refershPasswordWrapper}>
          <div className={logoWrapper}>
              <img src={logo} alt="Logo" />
          </div>

          <form className={formWrapper} onSubmit={confirmHandler} >
              <div className={[inputBox, code].join(' ')}>
                  <FormControl content={VerificationCodeFC} value={verificationCodeV} setParentValue={setVerificationCode} isValid={verificationCodeIsValid} setIsValid={setVerificationCodeIsValid} errorMsg={null}/>
              </div>
              <div className={[inputBox, password].join(' ')}>
                  <FormControl content={passwordX2FC} value={passV} setParentValue={setPassV} isValid={passIsValid} setIsValid={setPassIsValid} errorMsg={errorsObj.password}/>
              </div>
              <div className={[inputBox, confirmPassword].join(' ')}>
                <FormControl content={confirmPasswordX2FC} value={confirmPassV} setParentValue={setConfirmPassV} isValid={confirmPassIsValid} setIsValid={setConfirmPassIsValid} errorMsg={errorsObj.password}/>
              </div>
              <div className={refreshBtnBox}>
                <ButtonA children='Reset your password' type='primary' loading={loading} onClick={confirmHandler} externalClass={refreshBtn}/>
              </div>
              <div className={backBtnBox}>
                <ButtonA children='Back to login' type='secondary' onClick={() => navigate({pathname: PATHES.login},{replace: true})} externalClass={backBtn}/>
              </div>
          </form>
        
    
      
      </div>
    </div>
  </>

  )
}

export default Register