import React,{useRef, useEffect} from 'react'
import styles from './profileMenu.module.scss'
import {NavLink} from 'react-router-dom'
import {PATHES} from '../../utils/contants'
import useDetectClickedInside from '../customHooks/useDetectClickedInside'
import { useLocation } from 'react-router-dom'
import {useDispatch, UserReduxActionsT, UserReduxActionTypesT,ConfigsReduxActionTypesT} from '../../redux/index'
import {useNavigate} from 'react-router-dom'

const {menu,menuItem,activeItem} = styles


type ProfileMenuProps = {
    setShowMenu: (b:boolean) => void,
}


const ProfileMenu: React.FC<ProfileMenuProps> = (props: ProfileMenuProps) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const menuEl = useRef();
    const location = useLocation()

    let isInside = useDetectClickedInside(menuEl);
    useEffect(() => {
        if(!isInside){
            props.setShowMenu(false)
        }
    })


    const signOutHandler = (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault()
        dispatch({type: UserReduxActionTypesT.removeAuthUser})
        dispatch({type: ConfigsReduxActionTypesT.removeConfigs})
        navigate({pathname: PATHES.login},{replace: true})

    }

    const profileNavigator = (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault()
        navigate({pathname: PATHES.profile})
    }


    return(
    <ul className={menu} ref={menuEl}>
        <li className={[menuItem,location.pathname === PATHES.profile ? activeItem : ''].join(' ')} onClick={profileNavigator}>profile</li>
        <li className={menuItem} onClick={signOutHandler}>sign out</li>
    </ul>
    )
}

export default ProfileMenu