import styles from './filterD.module.scss'

import DatePicker from '../datePicker/reactDatePicker'
import ButtonA from '../buttons/buttonA'
import RightImg from '../../assets/images/right.png'
import CalenderImg from '../../assets/images/calender.png'
import AddCharsFormControl from '../formControl/addCharsFormControl/addCharsFormControl'

import FormControl,{SelectContent,OtherInputsContent} from '../formControl/formControl'
import { CharacterT } from '../../utils/models'
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory'


const {filterD, filterItem, filterLabel, filterValue, arrIcon, calIcon, firstChars,secondChars, lang, firstAccount, secondAccount, firstDevice, secondDevice, createdRange, confirmBtn, dateBox, refreshTime} = styles


type FilterProps = {
    createdAt?: {from: Date, to: Date}
    setCreatedAt?: (a: any) => void

    langsFC?: SelectContent
    langValue?: number
    setLangValue?: (e: any, value: number, label: string, object: object) => void

    charFC?: SelectContent
    firstCharValue?: number
    setFirstCharValue?: (e: any, value: number, label: string, object: object) => void

    secondCharValue?: number
    setSecondCharValue?: (e: any, value: number, label: string, object: object) => void

    confirmHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
    loading: boolean
    hasNotButton?: boolean
    
    refreshTimeFC?: OtherInputsContent
    refreshTimeValue?: string
    setRefreshTimeValue?: (a: any) => void
    refreshTimeIsValid?: boolean
    setRefreshTimeIsValid?: (a: any) => void
}

const Filter:React.FC<FilterProps> = ({
    langsFC, langValue, setLangValue,
    charFC, firstCharValue, setFirstCharValue, secondCharValue, setSecondCharValue,
    createdAt, setCreatedAt,
    refreshTimeFC, refreshTimeValue, setRefreshTimeValue,refreshTimeIsValid, setRefreshTimeIsValid,
    confirmHandler, loading, hasNotButton
}) => {

    
    
    return (
    <div className={filterD}>
   
        {
        createdAt && setCreatedAt ?
        <div className={[filterItem, createdRange].join(' ')}>
            <div className={filterLabel}>created at:</div>
            <div className={filterValue}>
                <div className={dateBox}><DatePicker date={createdAt.from} setDate={(date) => {setCreatedAt(prev => ({...prev,from: date}))}} placeholder="Start date"/></div>
                <img src={RightImg} className={arrIcon}/>
                <div className={dateBox}><DatePicker date={createdAt.to} setDate={(date) => {setCreatedAt(prev => ({...prev, to: date}))}} placeholder="End date"/></div>
                <img src={CalenderImg} className={calIcon}/>
            </div>
        </div>
        :""
        }
        


        {
        langsFC ?
        <div className={[filterItem, lang].join(' ')}>
            <div className={filterLabel}>language:</div>
            <div className={filterValue}>
                <FormControl content={langsFC} value={langValue} isValid={true} setIsValid={() => {}} errorMsg={null} optionChangeHandler={setLangValue} />
            </div>
        </div>
        :""
        }




        {
        charFC ? 
        <div className={[filterItem, firstChars].join(' ')}>
            <div className={filterLabel}>first character:</div>
            <div className={filterValue}>
                <FormControl content={charFC} value={firstCharValue} isValid={true} setIsValid={() => {}} optionChangeHandler={setFirstCharValue} errorMsg={null}/>
            </div>
        </div>
        : 
        ''
        }
        {
        //we dont want to show the secondChar if the firstChar is not selected
        charFC && firstCharValue !== 0 ?
        <div className={[filterItem, secondChars].join(' ')}>
            <div className={filterLabel}>second character:</div>
            <div className={filterValue}>
                <FormControl content={charFC} value={secondCharValue} isValid={true} setIsValid={() => {}} optionChangeHandler={setSecondCharValue} errorMsg={null}/>
            </div>
        </div>
        :''
        }
        {
        hasNotButton !== true &&
        <div className={confirmBtn}>
            <ButtonA children={'filter result'} type='primary' loading={loading} onClick={confirmHandler}/>
        </div>
        }
        {
        refreshTimeFC &&
        <div className={[filterItem, refreshTime].join(' ')}>
            <div className={filterLabel}>refresh time:</div>
            <div className={filterValue}>
                <FormControl content={refreshTimeFC} value={refreshTimeValue} setParentValue={setRefreshTimeValue} isValid={refreshTimeIsValid} setIsValid={setRefreshTimeIsValid} errorMsg={null}/>    
            </div>
        </div>
        }
    </div>
    )
}


export default Filter