import styles from './languageCountTable.module.scss'
import {TalkReportsLanguageT} from '../../../utils/models'
const { languageCountTable, headRow, bodyRow, noRecord} = styles

type LanguageMacthedCountTablePropsT = {
    data: TalkReportsLanguageT[]
}



const LanguageCountTable: React.FC<LanguageMacthedCountTablePropsT> = props => {
    const totalCount = props.data.reduce((acc,rec) => rec.count+acc, 0)
    const rows = props.data.map((record, index) => {
        let percentageValue = 0
        if(totalCount > 0){
            percentageValue = (record.count/totalCount)*100
        }
        return (
        <tr className={bodyRow} key={`${index}-seqe`}>
            <td>{record.language_name}</td>
            <td>{record.count}</td>
            <td>{percentageValue}</td>
        </tr>
        )
    })

    return(
        <>
            <table className={languageCountTable}>
                <thead>
                    <tr className={headRow}>
                        <td>Language</td>
                        <td>Call Count</td>
                        <td>Call Count( percentage) </td>
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={3}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </>
    )
}


export default LanguageCountTable