import React,{useState} from 'react'
import styles from './userEditDetails.module.scss'
import {firstNameFC, lastNameFC, emailFC, userNameFC, phoneNumberFC,} from '../../../utils/formControl'
import FormControl from '../../formControl/formControl'
import ButtonA from '../../buttons/buttonA'
import {validator, ValidatorTypes, Langs} from '../../../utils/validator'
import {DetailUpdatorArgT} from '../../../pages/userEdit/userEdit'
import {NAME_MIN_LENGTH,PASSWORD_MIN_LENGTH,USERNAME_MIN_LENGTH} from '../../../utils/contants'
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {AdminCreateNewUserReqBodyT, adminChangeUserDetails, AdminChangeUserDetailsReqBody} from '../../../utils/requests'
import {useDispatch} from '../../../redux/index'

const {userEditDetails, item, firstName, itemLabel, itemInput, itemValue, lastName, email, userName, phoneNumber, createdAt, updatedAt, confirmBtn} = styles

type UserEditDetailsProps = {
    data: {
        id: number
        first_name: string
        last_name: string 
        username: string
        phone_number: string
        email: string
        created_at: string
        updated_at: string
    }
    access_token: string
    refresh_token: string
    userDataUpdater: (arg: DetailUpdatorArgT) => void
}





const UserEditDetails: React.FC<UserEditDetailsProps> = (props: UserEditDetailsProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [firstNameV, setFirstNameV] = useState(props.data.first_name)
    const [firstNameIsValid, setFirstNameIsValid] = useState(true)
    
    const [lastNameV, setLastNameV] = useState(props.data.last_name)
    const [lastNameIsValid, setLastNameIsValid] = useState(true)

    const [userNameV,setUserNameV] = useState(props.data.username)
    const [userNameIsValid, setUserNameIsValid] = useState(true)

    const [phoneNumberV, setPhoneNumberV] = useState(props.data.phone_number)
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true)

    const [errorsObj, setErrorsObj] = useState({firstName: null, lastName: null, userName: null, phoneNumber: null})
    const [loading, setLoading] = useState(false)

  


    const confirmHandler = async(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if(loading) return

        //if user hasnt change the status, we dont want to send a req
        if(firstNameV === props.data.first_name && lastNameV === props.data.last_name &&
            phoneNumberV === props.data.phone_number && userName === props.data.username) return

        const populatedErrors = {firstName: null, lastName: null, userName: null, phoneNumber: null}
        let hasError = false

        const firstNameValidationResult = validator({type: ValidatorTypes.name, lang: Langs.en, value: firstNameV, nChar: NAME_MIN_LENGTH})
        const lastNameValidationResult = validator({type: ValidatorTypes.name, lang: Langs.en, value: lastNameV, nChar: NAME_MIN_LENGTH})
        const userNameValidationResult = validator({type: ValidatorTypes.minimumLengthString, value: userNameV, nChar: USERNAME_MIN_LENGTH})
        const phoneNumberValidationResult = validator({type: ValidatorTypes.phoneNumber, value: phoneNumberV, nChar: 11})
    
        if(!firstNameValidationResult.isValid){
            setFirstNameIsValid(false)
            populatedErrors.firstName = firstNameValidationResult.errs[0]
            hasError = true
        }
        if(!lastNameValidationResult.isValid){
            setLastNameIsValid(false)
            populatedErrors.lastName = lastNameValidationResult.errs[0]
            hasError = true
        }
        if(!userNameValidationResult.isValid){
            setUserNameIsValid(false)
            populatedErrors.userName = userNameValidationResult.errs[0]
            hasError = true
        }
        if(!phoneNumberValidationResult.isValid){
            setPhoneNumberIsValid(false)
            populatedErrors.phoneNumber = phoneNumberValidationResult.errs[0]
            hasError = true
        }  
        
        //if we have some error, we have to exit this func
        if(hasError){
            return setErrorsObj(populatedErrors)
        }

        const reqBody: AdminChangeUserDetailsReqBody = {
            user_id: props.data.id,
            first_name: firstNameV,
            last_name: lastNameV,
            phone_number: phoneNumberV,
            username: userNameV
        }
        try{
            setLoading(true)
            const res = await adminChangeUserDetails(props.access_token, reqBody, {dispatch,navigate,refresh_token: props.refresh_token,toast})
            setLoading(false)
            if(res.status === 200){
                toast.success('user has been updated')
                props.userDataUpdater({type: 'Detail', payload: reqBody})
                return
            }
            //if an error occured, we want to reverse the value of select back to its initial value comes from list
            setUserNameV(props.data.username)
            setFirstNameV(props.data.first_name)
            setLastNameV(props.data.last_name)
            setPhoneNumberV(props.data.phone_number)
        }
        catch(err){
            //if an error occured, we want to reverse the value of select back to its initial value comes from list
            setUserNameV(props.data.username)
            setFirstNameV(props.data.first_name)
            setLastNameV(props.data.last_name)
            setPhoneNumberV(props.data.phone_number)
            setLoading(false)
        }

    }




    return(
        <form className={userEditDetails}>
            <div className={[item,firstName].join(' ')}>
                <div className={itemLabel}>first name</div>
                <div className={itemInput}>
                    <FormControl content={firstNameFC} value={firstNameV} setParentValue={setFirstNameV} isValid={firstNameIsValid} setIsValid={setFirstNameIsValid} errorMsg={errorsObj.firstName}/>
                </div>
            </div>
            <div className={[item,lastName].join(' ')}>
                <div className={itemLabel}>last name</div>
                <div className={itemInput}>
                    <FormControl content={lastNameFC} value={lastNameV} setParentValue={setLastNameV} isValid={lastNameIsValid} setIsValid={setLastNameIsValid} errorMsg={errorsObj.lastName}/>
                </div>
            </div>
            <div className={[item,email].join(' ')}>
                <div className={itemLabel}>email</div>
                <div className={itemValue}>{props.data.email}</div>
            </div>
            <div className={[item,userName].join(' ')}>
                <div className={itemLabel}>user name</div>
                <div className={itemInput}>
                    <FormControl content={userNameFC} value={userNameV} setParentValue={setUserNameV} isValid={userNameIsValid} setIsValid={setUserNameIsValid} errorMsg={errorsObj.userName}/>
                </div>
            </div>
            <div className={[item,phoneNumber].join(' ')}>
                <div className={itemLabel}>phone number</div>
                <div className={itemInput}>
                    <FormControl content={phoneNumberFC} value={phoneNumberV} setParentValue={setPhoneNumberV} isValid={phoneNumberIsValid} setIsValid={setPhoneNumberIsValid} errorMsg={errorsObj.phoneNumber}/>
                </div>
            </div>
            <div className={[item,createdAt].join(' ')}>
                <div className={itemLabel}>created at</div>
                <div className={itemValue}>{props.data.created_at}</div>
            </div>
            <div className={[item,updatedAt].join(' ')}>
                <div className={itemLabel}>updated at</div>
                <div className={itemValue}>{props.data.created_at}</div>
            </div>
            <div className={confirmBtn}>
                <ButtonA type='primary' loading={loading} children='Confirm changes' onClick={(e) => {confirmHandler(e)}}/>
            </div>
        </form>
    )
}


export default UserEditDetails