import styles from './editProfile.module.scss'
import React,{useState, useEffect} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector, useDispatch, UserReduxActionTypesT} from '../../redux/index'
import { useNavigate, Navigate, useLocation } from 'react-router-dom'
import {PATHES} from '../../utils/contants'
import FormControl from '../../components/formControl/formControl'
import {validator, ValidatorTypes, Langs} from '../../utils/validator'
import {firstNameFC, lastNameFC, userNameFC, phoneNumberFC} from '../../utils/formControl'
import {editProfile,EditProfileReqBodyT} from '../../utils/requests'
import { toast } from 'react-toastify'
import { historyPropertiesExistanceCheck } from '../../utils/utility'

const {profile, container, item, itemLabel, itemInput, firstName, lastName, phoneNumber, userName } = styles

const EditProfile:React.FC = props => {
    const location = useLocation()
    const from = historyPropertiesExistanceCheck(location, 'from', null)
    const dispatch = useDispatch()
    const [firstNameV, setFirstNameV] = useState('')
    const [firstNameIsValid, setFirstNameIsValid] = useState(true)

    const [lastNameV, setLastNameV] = useState('')
    const [lastNameIsValid, setLastNameIsValid] = useState(true)

    const [userNameV,setUserNameV] = useState('')
    const [userNameIsValid, setUserNameIsValid] = useState(true)

    const [phoneNumberV, setPhoneNumberV] = useState('')
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true)

    const [errorsObj,setErrorsObj] = useState({firstName: null,lastName: null,userName: null,phoneNumber: null})

    const [loading, setLoading] = useState(false)

    const userRedux = useTypedSelector(state => state.user)
    const navigate = useNavigate()
    let entranceIsIllegal = false
    if(from !== PATHES.profile){
        entranceIsIllegal = true
    }

    //we want to put user info inside inputs as initial value
    useEffect(() => {
        if(userRedux.firstName) setFirstNameV(userRedux.firstName)
        if(userRedux.lastName) setLastNameV(userRedux.lastName)
        if(userRedux.userName) setUserNameV(userRedux.userName)
        if(userRedux.phoneNumber) setPhoneNumberV(userRedux.phoneNumber)
    },[userRedux])  




    const submitHandler = async() => {
        //if loading is true, it means we have sent a req and doesnt get its res yet, so we have to wait more and we cant send another req
        if(loading){
            return
        }
        let gatheredErrors = {firstName: null,lastName: null,userName: null,phoneNumber: null}
        let hasError = false
        const firstNameValidationResult = validator({type: ValidatorTypes.name, value: firstNameV, lang: Langs.en, nChar: 3})
        const lastNameValidationResult = validator({type: ValidatorTypes.name, value: lastNameV, lang: Langs.en, nChar: 3})
        const userNameValidationResult = validator({type: ValidatorTypes.minimumLengthString, value: userNameV, nChar: 5})
        const phoneNumberValidationResult = validator({type: ValidatorTypes.phoneNumber, value: phoneNumberV, nChar: 11})

        //now we have to checkValidations and set Errors for them
        if(!firstNameValidationResult.isValid){
            gatheredErrors.firstName = firstNameValidationResult.errs[0]
            setFirstNameIsValid(false)
            hasError = true
        }
        if(!lastNameValidationResult.isValid){
            gatheredErrors.lastName = lastNameValidationResult.errs[0]
            setLastNameIsValid(false)
            hasError = true
        }
        if(!userNameValidationResult.isValid){
            gatheredErrors.userName = userNameValidationResult.errs[0]
            setUserNameIsValid(false)
            hasError = true
        }
        if(!phoneNumberValidationResult.isValid){
            gatheredErrors.phoneNumber = phoneNumberValidationResult.errs[0]
            setPhoneNumberIsValid(false)
            hasError = true
        }
        //then we have to exit the func if we have error in some fields
        if(hasError){
            const {firstName,lastName,phoneNumber,userName} = gatheredErrors
            return setErrorsObj({firstName,lastName,userName,phoneNumber})
        }
        //here we know that all inputs have valid values
        const reqBody: EditProfileReqBodyT = {
            first_name: firstNameV,
            last_name: lastNameV,
            phone_number: phoneNumberV,
            username: userNameV
        }
        try{
            setLoading(true)
            const res = await editProfile(userRedux.access_token, reqBody,{dispatch,navigate,refresh_token: userRedux.refresh_token,toast}) 
            setLoading(false)
            if(res.status === 200){
                //first we have to save new user info into redux
                dispatch({type: UserReduxActionTypesT.updateUserInfo, payload: {firstName: firstNameV,lastName: lastNameV,phoneNumber: phoneNumberV, userName: userNameV}})
                navigate({pathname: PATHES.profile}, {state: {from: PATHES.profileEdit}})
                return toast.success('your changes were successfully confirmed')
                
            }
        }
        catch(err){
            setLoading(false)
        }
    }


    const confirmBtnProps: ButtonAProps = {
        type: 'primary',
        children: 'Confirm changes',
        onClick: submitHandler,
        loading: loading
    }
    const previousPageBtnProps: ButtonAProps = {
        type: 'secondary',
        children: 'Back to profile',
        onClick: () => {navigate({pathname: PATHES.profile})}
    }

    return (
      <Template>
        {
         entranceIsIllegal ? <Navigate to={{pathname: PATHES.home}} replace={true} />
         :
        <div className={profile}>
           <MainPanel title='Your profile' headButtons={[confirmBtnProps,previousPageBtnProps]}>
                <form className={container}>
                    <div className={[item,firstName].join(' ')}>
                        <div className={itemLabel}>first name</div>
                        <div className={itemInput}>
                            <FormControl content={firstNameFC} value={firstNameV} setParentValue={setFirstNameV} isValid={firstNameIsValid} setIsValid={setFirstNameIsValid} errorMsg={errorsObj.firstName}/>
                        </div>
                    </div>
                    <div className={[item,lastName].join(' ')}>
                        <div className={itemLabel}>last name</div>
                        <div className={itemInput}>
                            <FormControl content={lastNameFC} value={lastNameV} setParentValue={setLastNameV} isValid={lastNameIsValid} setIsValid={setLastNameIsValid} errorMsg={errorsObj.lastName}/>
                        </div>
                    </div>
                    <div className={[item,userName].join(' ')}>
                        <div className={itemLabel}>user name</div>
                        <div className={itemInput}>
                            <FormControl content={userNameFC} value={userNameV} setParentValue={setUserNameV} isValid={userNameIsValid} setIsValid={setUserNameIsValid} errorMsg={errorsObj.userName}/>
                        </div>
                    </div>
                    <div className={[item,phoneNumber].join(' ')}>
                        <div className={itemLabel}>phone number</div>
                        <div className={itemInput}>
                            <FormControl content={phoneNumberFC} value={phoneNumberV} setParentValue={setPhoneNumberV} isValid={phoneNumberIsValid} setIsValid={setPhoneNumberIsValid} errorMsg={errorsObj.phoneNumber}/>
                        </div>
                    </div>
                </form>
           </MainPanel>
        </div>
        }
      </Template>
    )
}

export default EditProfile

