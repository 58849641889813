import styles from './policyList.module.scss'
import React,{useEffect, useState} from 'react'
import MainPanel from '../../components/panels/mainPanel/mainPanel'
import {ButtonAProps} from '../../components/buttons/buttonA'
import Template from '../../components/template/template'
import {useTypedSelector} from '../../redux/index'
import {RoleT, BackendCompleteUserDocT} from '../../utils/models'
import { useNavigate } from 'react-router-dom'
import {PATHES} from '../../utils/contants'
import UserListTable from '../../components/tables/userListTable/userListTable'
import {getUserList} from '../../utils/requests'
import SpinnerB from '../../components/spinner/spinnerB'
import {Role_WithoutDefaultOption_FC_creator,UserStatusFC_creator} from '../../utils/formControl'
import {SelectContent, InputTypes} from '../../components/formControl/formControl'
import PolicyListTable from '../../components/tables/rolePolicyListTable/rolePolicyListTable'

const {userList, container,  } = styles

const PolicyList:React.FC = props => {
  const {user: userRedux, configs: configRedux} = useTypedSelector(state => ({user: state.user, configs: state.configs}))
  const [roleFC, setRoleFC] = useState<SelectContent | null>(null)



  //configs based on roles comes from redux for dropdown
  useEffect(() => {
    if(configRedux.allRoles.length > 0){
      const roleDropdownConfigs = Role_WithoutDefaultOption_FC_creator(configRedux.allRoles)
      setRoleFC(roleDropdownConfigs)
    }
  },[configRedux])


  const user_is_permitted_to_edit_policies = userRedux.resources.find(reso => reso.value === 3)?.actions.find(act => act.value === 4) ? true : false




  return (
    <Template>
      <div className={userList}>
        <MainPanel title='Policy List' headButtons={[]}>
          <div className={container} style={{width: '100%'} as React.CSSProperties}>
          {
            roleFC && configRedux && configRedux.allRoles.length > 0  && configRedux.allActions.length > 0 && configRedux.allResources.length > 0?
            <PolicyListTable roleFC={roleFC} allRoles={configRedux.allRoles} allActions={configRedux.allActions} allResources={configRedux.allResources} access_token={userRedux.access_token} refresh_token={userRedux.refresh_token} user_is_permitted_to_edit_policies={user_is_permitted_to_edit_policies}/>
            :
            <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
              <SpinnerB/>
            </div>
            }
          </div>
        </MainPanel>
      </div>
    </Template>
  )
}

export default PolicyList

