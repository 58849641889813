import styles from './languageDurationTable.module.scss'
import {TalkReportsLanguageT} from '../../../utils/models'
const { languageDurationTable, headRow, bodyRow, noRecord} = styles

type LanguageDurationTablePropsT = {
    data: TalkReportsLanguageT[]
}



const LanguageDurationTable: React.FC<LanguageDurationTablePropsT> = props => {
    const totalDurationInMinutes = props.data.reduce((acc,rec) => acc+rec.duration, 0)
    const rows = props.data.map((record, index) => {
        let percentageValue = 0
        if(totalDurationInMinutes > 0){
            percentageValue = (record.duration/totalDurationInMinutes)*100
        }
        return (
        <tr className={bodyRow} key={`${index}-sqws`}>
            <td>{record.language_name}</td>
            <td>{record.duration}</td>
            <td>{percentageValue}</td>
        </tr>
        )
    })

    return(
        <>
            <table className={languageDurationTable}>
                <thead>
                    <tr className={headRow}>
                        <td>Language</td>
                        <td>Call Duration</td>
                        <td>Call Duration( percentage) </td>
                    </tr>
                </thead>
                <tbody>
                    {
                    rows.length > 0 ?
                    rows : 
                    <tr className={noRecord}><td colSpan={3}>there is no record</td></tr>
                    }
                </tbody>
            </table>
        </>
    )
}


export default LanguageDurationTable