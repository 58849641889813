
/* eslint-disable */
import React from 'react';
import styles from './login.module.scss'
import logo from '../../assets/images/logo1.svg'
import { useState, useEffect } from 'react';
import { UserReduxActionTypesT, ConfigsReduxActionTypesT} from '../../redux/index'
import {sendLoginRequset,getUserInfo, getConfigs} from '../../utils/requests'
import { useDispatch as _useDispatch} from '../../redux/index'
import {LoginResponseT} from '../../utils/requests'
import {useNavigate} from 'react-router-dom'
import {PATHES} from '../../utils/contants'
import {toast} from 'react-toastify'
import Register from './register';
import {emailX2FC, confirmPasswordX2FC, passwordX2FC} from '../../utils/formControl'
import FormControl from '../../components/formControl/formControl';
import ButtonA from '../../components/buttons/buttonA';
import {validator, ValidatorTypes} from '../../utils/validator'
import ForgotPassword from './forgotPass';


const {pageWrapper, loginWrapper, tabWrapper, logoWrapper, formWrapper, inputBox, email, password, loginBtn, signUpWrapper, forgetPass, loginBtnBox} = styles

enum PageT {
  login = 'LOGIN',
  register = 'REGISTER',
  forgotPassword = 'FORGOT_PASSWORD'
} 


const Login: React.FC = () => {
  const [page, setPage] = useState<PageT>(PageT.login)
  const [accessToken,setAccessToken] = useState<string | null>(null)
  const [refreshToken, setRefreshToken] = useState<string | null>(null)
  const [emailV, setEmailV] = useState('')
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [passV, setPassV] = useState('')
  const [passIsValid, setPassIsValid] = useState(true)
  const [errorsObj, setErrorsObj] = useState({email: null, password: null})
  const [loading, setLoading] = useState(false)
  const dispatch = _useDispatch()
  const navigate = useNavigate()
  


  const confirmHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    //if we have sent a req already, but we havent recieved its response, we have to wait to get current req response, then send another req
    if(loading) return
    let hasError = false 
    const populatedErrors = {email: null, password: null}

    const emailValidationResult = validator({type: ValidatorTypes.email, value: emailV})
    const passwordValidationResult = validator({type: ValidatorTypes.minimumLengthString, value: passV, nChar: 8})
    //we dont want to have validation on password, because validation occures on the backend-side
    if(!emailValidationResult.isValid){
      hasError = true
      populatedErrors.email = emailValidationResult.errs[0]
      setEmailIsValid(false)
    }
    if(!passwordValidationResult.isValid){
      hasError = true
      populatedErrors.password = passwordValidationResult.errs[0]
      setPassIsValid(false)
    }
    //if we do have any validation error, we dont want to continue
    if(hasError){
      return setErrorsObj(populatedErrors)
    }
    try{
      setLoading(true)
      const res = await sendLoginRequset({email: emailV, password: passV},{dispatch,navigate,refresh_token: '123123',toast})
      setLoading(false)
      if (res.status === 200) {
        dispatch({type: UserReduxActionTypesT.createAuthToken, payload: {...res.data}})
        setAccessToken((res.data as LoginResponseT).tokens.access_token)
        setRefreshToken((res.data as LoginResponseT).tokens.refresh_token)
        if((res.data as LoginResponseT).permissions){
          toast.success('you have been logged in successfully')
        }
        else {
          toast.error('you dont have any permission to enter the panel')
        }
        return
      }
    }
    catch(err){
      setLoading(false)
    }
  };


  //after getting login response, we need to send a req to get the user info from the server
  useEffect(() => {
    const fetchDate = async (access_token,refresh_token) => {
      try{
        const res = await getUserInfo(access_token, {dispatch,navigate,refresh_token,toast})
        if(res.status === 200){
          dispatch({type: UserReduxActionTypesT.appendUserInfo, payload: {...res.data.data}})
          //after getting userInfo successfuly then we have to get the configs from backend
          const res1 = await getConfigs(access_token, {dispatch,navigate,refresh_token,toast})
          if(res1.status === 200){
            setLoading(false)
            // console.log('configData from backend ---->',res1.data)
            dispatch({ type:ConfigsReduxActionTypesT.saveConfigs, payload: res1.data})
          }
          //if every thing was saved including configs,userInfo  => then we redirect user to the homePage
          navigate({pathname: PATHES.home}, { replace: true })
          setLoading(false)
        }
      }
      catch(err){
        //if any problems happened, we have to remove token from redux and sessionStorage and redirect user to login
        dispatch({type: UserReduxActionTypesT.removeAuthUser})
        //after waiting for all req responses, if we get error, we have to set loading to false
        setLoading(false)
      }
    }
    if(accessToken && refreshToken){
      fetchDate(accessToken,refreshToken)
    }
  }, [accessToken, refreshToken])



  return (
  <div className={pageWrapper} >
    <div className={loginWrapper}>
      <div className={tabWrapper}>
        <div onClick={() => setPage(PageT.login)}>
          <span >Login</span>
        </div>

        <div onClick={() => setPage(PageT.register)}>
          <span >Register</span>
        </div>
      </div>
      <div className={logoWrapper}>
        <img src={logo} alt="Logo" />
      </div>

      {page ===  PageT.login ?
        <>
          <form className={formWrapper} onSubmit={confirmHandler} >
            <div className={[inputBox, email].join(' ')}>
              <FormControl content={emailX2FC} value={emailV} setParentValue={setEmailV} isValid={emailIsValid} setIsValid={setEmailIsValid} errorMsg={errorsObj.email}/>
            </div>
            <div className={[inputBox, password].join(' ')}>
              <FormControl content={passwordX2FC} value={passV} setParentValue={setPassV} isValid={passIsValid} setIsValid={setPassIsValid} errorMsg={errorsObj.password}/>
            </div>
            <div className={loginBtnBox}>
              <ButtonA children='Login' type='primary' loading={loading} onClick={confirmHandler} externalClass={loginBtn}/>
            </div>
          </form>
          <div className={signUpWrapper}>
            <h4 className={forgetPass} onClick={() => setPage(PageT.forgotPassword)}>Forget Password?</h4>
          </div>
        </>
        :
        page === PageT.register ? 
        <Register /> :
        page === PageT.forgotPassword ?
        <ForgotPassword />: 
        ''
      }
    </div>
  </div>

  )
}

export default Login