import React from 'react'
import styles from './buttonA.module.scss'
import Spinner from '../spinner/spinner'
const {buttonA} = styles

export type ButtonAProps = {
    children: string | number
    onClick: (event?: any) => void
    type: 'primary' | 'secondary' 
    externalClass?: any
    loading?: boolean
}

const ButtonA: React.FC<ButtonAProps> = (props: ButtonAProps) => {

    return (
        <button className={[buttonA, props.externalClass, styles[props.type]].join(' ')} onClick={props.onClick} style={{minWidth: `${props.children.toString().length*1.2}ch`} as React.CSSProperties}>
            {!props.loading ? props.children : <Spinner/>}
        </button>
    )
}

export default ButtonA